import React, { useEffect, useState, useRef } from 'react'
import ChatBody from './ChatBody'
import ChatFooter from './ChatFooter'
import Header from './Header'
import Sidebar from './Sidebar'
import Titlebar from './Titlebar'
import GameBox from './GameBox'
import SeasonBox from './SeasonBox'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import Modal from './Modal'
import Slider from './Slider'
import Box from './Box'

const {
  REACT_APP_DATABASE_BASEURL_DEV,
  REACT_APP_DATABASE_BASEURL,
  REACT_APP_NODE_ENV,
  REACT_APP_BANNERS_DEV,
  REACT_APP_BANNERS,
  REACT_APP_REFRESH_DEV,
  REACT_APP_REFRESH,
  REACT_APP_QUESTBOX_DEV,
  REACT_APP_QUESTBOX,
  REACT_APP_MAXPLAYERSNUM_DEV,
  REACT_APP_MAXPLAYERSNUM
} = process.env;

//is winter 10.12 - 01.02.
const today = new Date();
const startDate = new Date(today.getFullYear(), 9, 12); // Month is zero-based, so 9 represents October
const endDate = new Date(today.getFullYear() + 1, 1, 2); // Month is zero-based, so 1 represents February of the next year
const isWinter = today >= startDate && today <= endDate;

//is christmass 24.12. - 28.12.
const startDate2 = new Date(today.getFullYear(), 11, 24); // Month is zero-based, so 11 represents December
const endDate2 = new Date(today.getFullYear(), 11, 28); // Month is zero-based, so 11 represents December
const isChristmass = today >= startDate2 && today <= endDate2;

//is newYear 01.01. - 02.01.
const startDate3 = new Date(today.getFullYear(), 0, 1); // Month is zero-based, so 11 represents December
const endDate3 = new Date(today.getFullYear(), 0, 2); // Month is zero-based, so 11 represents December
const isNewYear = today >= startDate3 && today <= endDate3;

const ChatPage = ({ socket }) => {
  const navigate = useNavigate()
  const [newsList, setNewsList] = useState([])
  const [gameOn, setGameOn] = useState(true)
  const currUser = localStorage.getItem('userName')
  const [users, setUsers] = useState([])
  const [gamers, setGamers] = useState({})
  const [showUsers, setShowUsers] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [to, setTo] = useState(null)
  const [shoutsCount, setShoutsCount] = useState(0)
  const [mmNew, setMMNew] = useState(0)
  const [statusHistory, setStatusHistory] = React.useState([])
  const [questLength, setQuestLength] = useState(0)
  const [questBox, setQuestBox] = React.useState([])
  //const [userHasScrolled, setUserHasScrolled] = useState(false)
  const [banners, setBanners] = React.useState([])
  const [maxPlayersNum, setMaxPlayersNum] = React.useState(null)
  const [currUserAvatar, setCurrUserAvatar] = React.useState(null)
  const [showDonationModal, setShowDonationModal] = React.useState(false)
  const [donators, setDonators] = React.useState([])
  const [me, setMe] = React.useState({})
  const [lastSeason, setLastSeason] = React.useState(null)
  const [showSeasonsModal, setShowSeasonsModal] = React.useState(false)
  const [currEvent, setCurrEvent] = React.useState({})
  const [showEventsModal, setShowEventsModal] = React.useState(false)
  const [showSingleNewsModal, setShowSingleNewsModal] = useState(false)
  const [singleNews, setSingleNews] = useState(null)
  const [menuActive, setMenuActive] = React.useState(window.innerWidth > 780 ? true : false)
  const [showNewsModal, setShowNewsModal] = React.useState(false)
  const [showUsersModal, setShowUsersModal] = React.useState(false)
  const [currSingleUserName, setCurrSingleUserName] = React.useState(null)
  const [showSingleUserModal, setShowSingleUserModal] = React.useState(null)
  const [multiplayerActive, setMultiplayerActive] = useState(false)
  const [showAddNewsModal, setShowAddNewsModal] = useState(false)
  const [isSidebarRightMobileVisible, setIsSidebarRightMobileVisible] = useState(true)
  const [showMMModal, setShowMMModal] = React.useState(false)

  const [width, setWidth] = useState(window.innerWidth);
  const isMobile = width <= 650;

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }

  const validateToken = async () => {

    if (!localStorage.getItem("userName")) {
      //ako nema usernejma u localstoriću bye bye
      localStorage.clear()
      navigate('/')
    }

    if (localStorage.getItem("token")) {
      // i ako mu token nije validan adio
      try {
        const response = await axios.post(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/validate`, {}, {
          headers: {
            'authorization': localStorage.getItem("token")
          }
        })

        if (response.data.status === 0) {
          handleKickUser(localStorage.getItem("userName"))
        }

      } catch (e) {

      }
    }
  }

  React.useEffect(() => {
    validateToken()
  }, [])

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  React.useEffect(() => {
    socket.on('hideBanners', () => {
      setMultiplayerActive(!multiplayerActive)
    })
  }, [socket, multiplayerActive])

  const handleMMBtnClick = () => {
    setShowMMModal(true)
  }

  const handleCloseMMModal = () => {
    setShowMMModal(false)
  }

  const handleStartOpeningSingleUserModal = (nameToSet) => {
    setCurrSingleUserName(nameToSet)
    handleOpenSingleUserModal()
  }

  const handleSetIsSidebarRightMobileVisible = () => {
    setIsSidebarRightMobileVisible(!isSidebarRightMobileVisible)
  }

  const handleOpenSingleUserModal = () => {
    setShowSingleUserModal(true)
  }

  const handleCloseSingleUserModal = () => {
    setShowSingleUserModal(false)
  }

  const openShowAddNewsModal = () => {
    setShowAddNewsModal(true)
  }

  const closeShowAddNewsModal = () => {
    setShowAddNewsModal(false)
  }

  const handleOpenUsersModal = () => {
    setShowUsersModal(true)
  }

  const handleCloseUsersModal = () => {
    setShowUsersModal(false)
  }

  const handleOpenNewsModal = () => {
    setShowNewsModal(true)
  }

  const handleCloseNewsModal = () => {
    setShowNewsModal(false)
  }

  const toggleMenu = () => {
    setMenuActive(!menuActive)
  }

  const handleOpenSingleNewsModal = (id) => {
    setSingleNews(id)
    setShowSingleNewsModal(true)
  }

  const handleCloseSingleNewsModal = () => {
    setShowSingleNewsModal(false)
  }

  const getMe = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_NODE_ENV === 'development' ?
          REACT_APP_DATABASE_BASEURL_DEV :
          REACT_APP_DATABASE_BASEURL}/user-name/${localStorage.getItem('userName')}`
      )

      if (response.data.status === 1) {
        setMe(response.data.user)

      }
    } catch (e) {
      console.log(e)
    }
  }

  const getLastSeason = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_NODE_ENV === 'development' ?
          REACT_APP_DATABASE_BASEURL_DEV :
          REACT_APP_DATABASE_BASEURL}/seasons-last`
      )

      if (response.data.status === 1) {
        setLastSeason(response.data.season)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const refreshFill = async () => {
    try {
      const data = await axios.get(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_REFRESH_DEV : REACT_APP_REFRESH}`)
      //ako ima username u localstorage-u ali nije u userima bye bye, zato što je passanger resetira server zbog neaktivnosti
      const localStorageUserIndex = data.data.users.findIndex(u => u.userName === localStorage.getItem("userName"))
      if (localStorageUserIndex === -1) {
        localStorage.clear()
        navigate('/')
      }

      setUsers(data.data.users)
      setGamers(data.data.gamers)
      setShoutsCount(data.data.shoutsCount)
      setMMNew(data.data.mmNew)
      setStatusHistory(data.data.statusHistory)
      setDonators(data.data.donators)

      //const currentUserIndex = data.data.users.findIndex(u => u.userName === currUser)

      if (localStorageUserIndex !== -1) {
        setCurrUserAvatar(data.data.users[localStorageUserIndex].avatar)
      }
    } catch (e) {
      console.log(e)
    }
  }

  const isEvent = async () => {
    try {
      const response = await axios.get(
        `${REACT_APP_NODE_ENV === 'development' ?
          REACT_APP_DATABASE_BASEURL_DEV :
          REACT_APP_DATABASE_BASEURL}/event`
      )

      if (response.data.status === 1 && today.setHours(0, 0, 0, 0) <= new Date(response.data.event[0].datum).setHours(0, 0, 0, 0)) {
        setCurrEvent(response.data.event[0])
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getNews = async () => {
    const data = await axios.get(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/news`)
    setNewsList(data.data.news.reverse())
  }


  //logout user from all devices simulacija
  React.useEffect(() => {
    socket.on('logoutUser', u => {
      if (u === localStorage.getItem('userName')) {
        handleKickUser(u)
      }
    })

    socket.on('refreshSeasonHomeBox', () => {
      getLastSeason()
    })

  }, [socket])

  React.useEffect(() => {
    getLastSeason()
    isEvent()
  }, [])

  React.useEffect(() => {
    getMe()
  }, [users])

  React.useEffect(() => {
    socket.on('updateDonatorsResponse', data => {
      setDonators(data)
    })
  }, [socket, donators])

  //banneri
  useEffect(() => {

    function shuffle(array) {
      let currentIndex = array.length, randomIndex;

      // While there remain elements to shuffle.
      while (currentIndex != 0) {

        // Pick a remaining element.
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex--;

        // And swap it with the current element.
        [array[currentIndex], array[randomIndex]] = [
          array[randomIndex], array[currentIndex]];
      }

      return array;
    }

    const getBanners = async () => {
      const data = await axios.get(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_BANNERS_DEV : REACT_APP_BANNERS}`)
      const randomiseBanners = shuffle(data.data)
      setBanners(randomiseBanners)
    }
    getBanners()

    getMe()

    /* const startChating = setTimeout(() => {
      setUserHasScrolled(true)
      clearTimeout(startChating)
    }, 10000)*/
  }, [])

  useEffect(() => {
    socket.on('updateBanners', newBanners => {
      setBanners(newBanners)
    })
  }, [socket, banners])

  //popuni usere i gamerenakon renderiranja
  useEffect(() => {
    refreshFill()
  }, [])

  useEffect(() => {
    socket.on('updateQuest', data => {
      setQuestLength(data)
    })
  }, [socket, questLength])

  React.useEffect(() => {
    socket.on('statusHistory', data => {
      setStatusHistory(data)
    })
  }, [socket, statusHistory])

  React.useEffect(() => {
    socket.on('newMMNewResponse', (data) => {
      setMMNew(data)
    })
  }, [socket, mmNew])

  React.useEffect(() => {
    socket.on('newMMresponse', (totalShouts) => {
      setShoutsCount(totalShouts)
    })
  }, [socket, shoutsCount])

  const handleOpenEventsModal = () => {
    setShowEventsModal(true)
  }

  const handleCloseEventsModal = () => {
    setShowEventsModal(false)
  }

  const handleOpenSeasonsModal = () => {
    setShowSeasonsModal(true)
  }

  const handleCloseSeasonsModal = () => {
    setShowSeasonsModal(false)
  }

  /* const handleScroll = () => {
    setUserHasScrolled(true)
  }; */

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleOpenModal = (userName) => {
    setTo(userName)
    setShowModal(true)
  }

  const handleToggleUsers = () => {
    setShowUsers(!showUsers)
  }

  useEffect(() => {
    socket.on("newUserResponse", data => {
      setUsers(data)
    })
  }, [socket, users])

  //ako je jedan otiša iz multiplayera i osta samo jedan user, neka i njega makne
  useEffect(() => {
    if (users.length === 1 && Object.keys(gamers).length === 1) {
      socket.emit('leaveMP', currUser)
    }
  }, [users])

  useEffect(() => {
    socket.on('updateGamers', data => {
      setGamers(data)
    })
  }, [socket, gamers])

  //kickanje
  const handleKickStatus = (s) => {
    socket.emit('removeStatus', s)
  }

  const handleKickMp = (u) => {
    socket.emit('leaveMP', u)
  }

  const handleKickUser = async (u) => {

    try {
      const response = await axios.post(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/logout`, {}, {
        headers: {
          'authorization': localStorage.getItem("token")
        }
      })
      //kakav god bio odgovr ga izlogiraj
      if (gamers.hasOwnProperty(u)) {
        handleKickMp(u)
      }
      socket.emit('logoutUser', u)
      if (u === localStorage.getItem('userName')) {
        localStorage.clear()
        navigate('/')
      }

    } catch (e) {
      if (gamers.hasOwnProperty(u)) {
        handleKickMp(u)
      }
      socket.emit('logoutUser', u)
      localStorage.clear()
      navigate('/')
    }
  }

  //random 0-100 generator
  const generateRandom = (maxLimit = 100) => {
    let rand = Math.random() * maxLimit;
    rand = Math.floor(rand);
    return rand;
  }

  //easter egg
  const easterEgg = () => {
    socket.emit('easterEgg', currUser)
  }


  useEffect(() => {
    socket.on("gameResponse", () => {
      setGameOn(!gameOn)
    })
  }, [socket, gameOn])

  useEffect(() => {
    getNews()
  }, [])

  useEffect(() => {
    socket.on('updateNews', () => {
      getNews()
    })
  }, [socket, newsList])

  useEffect(() => {
    const getQuestBox = async () => {
      const data = await axios.get(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_QUESTBOX_DEV : REACT_APP_QUESTBOX}`)
      setQuestBox(data.data)
    }
    getQuestBox()

    const getMaxPlayersNum = async () => {
      const data = await axios.get(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_MAXPLAYERSNUM_DEV : REACT_APP_MAXPLAYERSNUM}`)
      setMaxPlayersNum(data.data.maxPlayersNum[0])
    }
    getMaxPlayersNum()

  }, [])

  useEffect(() => {
    socket.on('updateQuestBox', data => {
      setQuestBox(data)
    })
  }, [socket, questBox])

  const handleOpenDonationModal = () => {
    setShowDonationModal(true)
  }

  const handleCloseDonationModal = () => {
    setShowDonationModal(false)
  }

  const renderNews = () => {
    return newsList.map((news) => {
      return <div key={`${Math.random()}`}>
        <Box isInSidebar={true} isInModal={false} handleOpenSingleNewsModal={handleOpenSingleNewsModal} key={`${Math.random()}${newsList.length}`} news={news} showBtn={true} />
      </div>
    })
  }

  const getUsers = (type) => {

    const usersToMap = users.filter(u => u.type === type)
    return usersToMap.map(user => {
      if (user.userName === 'TheCreator') {
        return (
          <div onClick={() => { handleStartOpeningSingleUserModal(user.userName) }} key={user.socketID} className={`${currUser === 'TheCreator' ? 'curr-user-wrap' : 'user-wrap'}`} style={{
            minWidth: '100%'
          }}>
            <img className="avatar-img" src={user.avatar}></img>
            <div className="game-bg username-wrap creator" style={{
              minWidth: '80%'
            }}>
              <a className="row middle">
                {`${user.userName} ${currUser === 'TheCreator' ? '(you)' : ''}`}
              </a>
            </div>
          </div>
        )
      }

      if (user.userName === currUser) {
        return (
          <div key={user.socketID} className="curr-user-wrap">
            <div className="avatar-wrap">
              <span className="lvl-bubble lvl-bubble-alt">{user.level}</span>
              <img className="avatar-img" src={user.avatar}></img></div>
            {type !== 'gamer' && user.winner || user.userName === 'Superadmin' ? (
              <div className="game-bg username-wrap" style={{
                width: '100%'
              }}>
                <a>{`${user.userName} (you)`}</a>
              </div>
            ) : (
              <div className="game-bg username-wrap" style={{
                width: `${!gamers[user.userName] ? 0 : gamers[user.userName] / questLength * 100}%`
              }}>
                <a>{`${user.userName} (you)`}</a>
              </div>
            )}
          </div>
        )
      } else {
        return (
          <div onClick={() => { handleStartOpeningSingleUserModal(user.userName) }} key={user.socketID} className="user-wrap" style={{
            minWidth: '100%'
          }}>
            <div className="avatar-wrap">
              <span className="lvl-bubble lvl-bubble-alt">{user.level}</span>
              <img className="avatar-img" src={user.avatar} />
            </div>

            {type !== 'gamer' && user.winner || user.userName === 'Superadmin' ? (
              <div className="game-bg username-wrap" style={{
                width: '100%'
              }}>
                <a className="row middle">
                  {user.userName}
                  {
                    user.isMobile
                      ?
                      <img className={`is-on-mobile-img ${user.winner ? 'margin-left-15' : 'margin-left'}`} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+UlEQVR4nM3UsS4EURjF8XkCvWeQEC9gn0K5thGVJxAVBbVOQ0FPYisqap5gkxulEApBIRs/ucm3sdnscMconOQk3+Q7+efMzJ2pqinCIp5xi5mqrdDzpbm/AC7hAueYbQtbwB72f3DOzJcAb5TrugSYIryK5RqvRSaVAt/Q/aZZNzKpFPg68aaPcTV23YtM+g3wCOtxLl/aAofYwQdWxp5vq4bT1Bg4RAe7Ne5EJpUCs+7zOavxQ2RSE2AfhzFvYTvmg9g1BpYoNQGeRZtRw+z/1fASpzGP/jBZJ7ErBg4inL+Mp5jvwlmPscsalAA38V5wuzmzMQn4BJelMShS1K6lAAAAAElFTkSuQmCC" />
                      :
                      <img className={`is-on-mobile-img ${user.winner ? 'margin-left-15' : 'margin-left'}`} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAApUlEQVR4nO3SQQ7DMAhE0X//S9NVN5aiUgrGpvO2kRL/jEEGsU1QyGFLWNUy14dYU8CbQlbWjCwKuWERCt9d9S2FeJgDWSpfrpAILeKgqxWhq+WgqxUx+mrtxO0hPJxDIXbIEtctQlVIF3OGzg+xZihkoUWS8OGH4pV1kOj3UMgiMndksV+f/3fITjycwx2gkGQsi3y9xLgQ6WaHQSGHYMrVQrjfC5aA/vCp8lUEAAAAAElFTkSuQmCC" />
                  }
                </a>
              </div>
            ) : (
              <div className="game-bg username-wrap" style={{
                width: `${!gamers[user.userName] ? 0 : gamers[user.userName] / questLength * 100}%`
              }}>
                <a className="row middle">
                  {user.userName}
                  {
                    user.isMobile
                      ?
                      <img className="is-on-mobile-img margin-left" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA+UlEQVR4nM3UsS4EURjF8XkCvWeQEC9gn0K5thGVJxAVBbVOQ0FPYisqap5gkxulEApBIRs/ucm3sdnscMconOQk3+Q7+efMzJ2pqinCIp5xi5mqrdDzpbm/AC7hAueYbQtbwB72f3DOzJcAb5TrugSYIryK5RqvRSaVAt/Q/aZZNzKpFPg68aaPcTV23YtM+g3wCOtxLl/aAofYwQdWxp5vq4bT1Bg4RAe7Ne5EJpUCs+7zOavxQ2RSE2AfhzFvYTvmg9g1BpYoNQGeRZtRw+z/1fASpzGP/jBZJ7ErBg4inL+Mp5jvwlmPscsalAA38V5wuzmzMQn4BJelMShS1K6lAAAAAElFTkSuQmCC" />
                      :

                      <img className="is-on-mobile-img margin-left" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAApUlEQVR4nO3SQQ7DMAhE0X//S9NVN5aiUgrGpvO2kRL/jEEGsU1QyGFLWNUy14dYU8CbQlbWjCwKuWERCt9d9S2FeJgDWSpfrpAILeKgqxWhq+WgqxUx+mrtxO0hPJxDIXbIEtctQlVIF3OGzg+xZihkoUWS8OGH4pV1kOj3UMgiMndksV+f/3fITjycwx2gkGQsi3y9xLgQ6WaHQSGHYMrVQrjfC5aA/vCp8lUEAAAAAElFTkSuQmCC" />
                  }
                </a>
              </div>
            )}
          </div>
        )
      }
    })
  }

  const generateBtnText = () => {

    if (Object.keys(gamers).length === 0) {
      return 'Pokreni'
    }

    if (Object.hasOwn(gamers, currUser)) {
      return 'Napusti'
    } else {
      return 'Priključi se'
    }
  }

  const formatEventDate = () => {
    const currEventDate = new Date(currEvent.datum)
    return `${String(currEventDate.getDate()).padStart(2, '0')}.${String(currEventDate.getMonth() + 1).padStart(2, '0')}.${currEventDate.getFullYear()}`
  }

  return (
    <div>
      <div className="ani-bg">

      </div>
      <div className="app">
        <div onClick={easterEgg} className="app-popup" style={{
          top: `${generateRandom()}%`,
          left: `${generateRandom()}%`
        }}></div>
        <Header
          statusHistory={statusHistory}
          users={users}
          currUser={currUser}
          socket={socket}
          toggleUsers={handleToggleUsers}
          showUsers={showUsers}
          handleKickUser={handleKickUser}
          banners={banners}
          questBox={questBox}
          newsList={newsList}
          getNews={getNews}
          avatar={currUserAvatar}
          me={me}
          isWinter={isWinter}
          currEvent={currEvent}
          formatEventDate={formatEventDate}
          toggleMenu={toggleMenu}
          menuActive={menuActive}
          handleOpenNewsModal={handleOpenNewsModal}
          handleOpenUsersModal={handleOpenUsersModal}
          handleStartOpeningSingleUserModal={handleStartOpeningSingleUserModal}
        />
        <div className="wrapper">
          <Sidebar
            getUsers={getUsers}
            handleOpenModal={handleOpenModal}
            setTo={setTo}
            currUser={currUser}
            gamers={gamers}
            users={users}
            socket={socket}
            showUsers={showUsers}
            toggleUsers={handleToggleUsers}
            handleOpenDonationModal={handleOpenDonationModal}
            isWinter={isWinter}
          />
          <div className="main-container">
            <Titlebar
              me={me}
              statusHistory={statusHistory}
              mmNew={mmNew}
              shoutsCount={shoutsCount}
              handleKickStatus={handleKickStatus}
              gamers={gamers}
              currUser={currUser}
              socket={socket}
              users={users}
              maxPlayersNum={maxPlayersNum}
              questBox={questBox}
              handleOpenSeasonsModal={handleOpenSeasonsModal}
              isWinter={isWinter}
              handleOpenEventsModal={handleOpenEventsModal}
              menuActive={menuActive}
              newsList={newsList}
              getNews={getNews}
              handleOpenNewsModal={handleOpenNewsModal}
              handleOpenUsersModal={handleOpenUsersModal}
            />
            {!multiplayerActive && <div className="slider-wrapper">
              <Slider isWinter={isWinter} banners={banners} />

              {isMobile && <div onClick={handleSetIsSidebarRightMobileVisible} className="close-sidebar-right-mobile">
                &#8645;
              </div>}

              {isMobile && isSidebarRightMobileVisible && <div onClick={openShowAddNewsModal} className="add-sidebar-right-mobile">Dodaj temu/vijest +</div>}

            </div>}
            {/*  <div onScroll={handleScroll} className="content-wrapper"> */}

            {!multiplayerActive && isMobile && isSidebarRightMobileVisible && <div className="sidebar-right-mobile">

              {Object.keys(currEvent).length !== 0 ? <GameBox isInSidebar={true} title='nethr.chat party!' sub={`${currEvent.lokacija}, ${formatEventDate()} u ${currEvent.vrijeme}h`} sub2={currEvent.tip} btnText="Opširnije" imgSrc="party.png" socket={socket} handleOpenEventsModal={handleOpenEventsModal} /> : null}

              {isChristmass ? <GameBox isInSidebar={true} title='Sretan Božić!' sub='Dragi prijatelji. Sretan vam Božić!' sub2="Puno zdravlja i sriće!" btnText="empty" imgSrc="santa.png" socket={socket} /> : null}

              {isNewYear ? <GameBox isInSidebar={true} title='Sretna Nova Godina!' sub='Sretnu i uspješnu Novu godinu!' sub2="Svako dobro!" btnText="empty" imgSrc="newyear.png" socket={socket} /> : null}


              <GameBox
                isInSidebar={true}
                imgSrc="egg.png"
                currUser={currUser}
                users={users}
                socket={socket}
                title='Easter egg'
                sub='Pronađite uskršnje jaje i osvojite'
                sub2='"winner" boju i prvu poziciju!'
              />

              {users.length !== 1 ? <GameBox isInSidebar={true} handleKickMp={handleKickMp} title='Multiplayer' sub='Natjecanje u tipkanju.' sub2='Pobjednik osvaja posebnu boju i prvu poziciju!' btnText={generateBtnText()} currUser={currUser} users={users} gamers={gamers} socket={socket} /> : null}

              {lastSeason && <SeasonBox isInSidebar={true} season={lastSeason} showBtn={true} handleOpenSeasonsModal={handleOpenSeasonsModal} />}

              {renderNews()}
            </div>}

            <div className="content-wrapper">
              {/* <Slider isWinter={isWinter} banners={banners} /> */}

              {!multiplayerActive && <div className="sidebar-right">

                {Object.keys(currEvent).length !== 0 ? <GameBox isInSidebar={true} title='nethr.chat party!' sub={`${currEvent.lokacija}, ${formatEventDate()} u ${currEvent.vrijeme}h`} sub2={currEvent.tip} btnText="Opširnije" imgSrc="party.png" socket={socket} handleOpenEventsModal={handleOpenEventsModal} /> : null}

                {isChristmass ? <GameBox isInSidebar={true} title='Sretan Božić!' sub='Dragi prijatelji. Sretan vam Božić!' sub2="Puno zdravlja i sriće!" btnText="empty" imgSrc="santa.png" socket={socket} /> : null}

                {isNewYear ? <GameBox isInSidebar={true} title='Sretna Nova Godina!' sub='Sretnu i uspješnu Novu godinu!' sub2="Svako dobro!" btnText="empty" imgSrc="newyear.png" socket={socket} /> : null}


                <GameBox
                  isInSidebar={true}
                  imgSrc="egg.png"
                  currUser={currUser}
                  users={users}
                  socket={socket}
                  title='Easter egg'
                  sub='Pronađite uskršnje jaje i osvojite'
                  sub2='"winner" boju i prvu poziciju!'
                />

                {users.length !== 1 ? <GameBox handleKickMp={handleKickMp} isInSidebar={true} title='Multiplayer' sub='Natjecanje u tipkanju.' sub2='Pobjednik osvaja posebnu boju i prvu poziciju!' btnText={generateBtnText()} currUser={currUser} users={users} gamers={gamers} socket={socket} /> : null}

                {lastSeason && <SeasonBox isInSidebar={true} season={lastSeason} showBtn={true} handleOpenSeasonsModal={handleOpenSeasonsModal} />}

                {renderNews()}


              </div>}

              {!multiplayerActive && <button onClick={openShowAddNewsModal} className="content-button sidebar-right_btn">Dodaj temu/vijest +</button>}

              <ChatBody
                users={users}
                currUser={currUser}
                socket={socket}
                gamers={gamers}
                getUsers={getUsers}
                //userHasScrolled={userHasScrolled}
                me={me}
                handleOpenSingleNewsModal={handleOpenSingleNewsModal}
              />
            </div>
            <ChatFooter
              socket={socket}
              users={users}
              me={me}
              isWinter={isWinter}
              handleMMBtnClick={handleMMBtnClick}
            />
          </div>
        </div >
        {
          showMMModal && <Modal
            currUser={currUser}
            handleCloseModal={handleCloseMMModal}
            socket={socket}
            title=''
            type='matchmakingQueue'
            users={users}
            me={me}
          />
        }
        {
          showAddNewsModal && <Modal
            handleCloseModal={closeShowAddNewsModal}
            socket={socket}
            modalTitle='Dodaj vijest'
            type='addnews'
            me={me}
            getNews={getNews}
          />
        }
        {
          showModal && <Modal
            handleCloseModal={handleCloseModal}
            socket={socket}
            title={`Privatni razgovor sa ${to}`}
            type='pvt'
            to={to}
            currUser={currUser}
            users={users}
            me={me}
            pm={true}
          />
        }
        {
          showDonationModal && <Modal
            handleCloseModal={handleCloseDonationModal}
            socket={socket}
            title={`Počasti me`}
            type='donation'
            donators={donators}
          />
        }
        {
          showSeasonsModal && <Modal
            handleCloseModal={handleCloseSeasonsModal}
            socket={socket}
            title='nethr.chat wars - pregled sezona'
            type='seasons'
          />
        }
        {
          showEventsModal && <Modal
            handleCloseModal={handleCloseEventsModal}
            type='events'
            socket={socket}
            currEvent={currEvent}
            formatEventDate={formatEventDate}
          />
        }
        {
          showSingleNewsModal && <Modal
            handleCloseModal={handleCloseSingleNewsModal}
            type='singlenews'
            socket={socket}
            singleNews={singleNews}
            newsList={newsList}
            me={me}
            currUser={currUser}
          />
        }
        {
          showNewsModal && <Modal
            handleCloseModal={handleCloseNewsModal}
            newsList={newsList}
            socket={socket}
            title='Daily robo-news'
            type="news"
            me={me}
            currUser={currUser}
            getNews={getNews}
          />
        }
        {
          showUsersModal && <Modal
            handleCloseModal={handleCloseUsersModal}
            socket={socket}
            title='Korisnici'
            type="korisnici"
            currUser={currUser}
            me={me}
            currSingleUserName={currSingleUserName}
            setCurrSingleUserName={setCurrSingleUserName}
            handleKickUser={handleKickUser}
          />
        }
        {
          showSingleUserModal && <Modal
            handleCloseModal={handleCloseSingleUserModal}
            socket={socket}
            type="single-user"
            currUser={currUser}
            me={me}
            currSingleUserName={currSingleUserName}
            handleKickUser={handleKickUser}
          />
        }


        <div className={showModal || showMMModal || showAddNewsModal || showSingleUserModal || showUsersModal || showNewsModal || showSingleNewsModal || showEventsModal || showSeasonsModal || showDonationModal || showUsers ? 'overlay-app' : ''}></div>
        {
          /* Christmass snowflakes */
          isWinter && <div className="snowflakes" aria-hidden="true">
            <div className="snowflake">
              <div className="inner">❅</div>
            </div>
            <div className="snowflake">
              <div className="inner">❅</div>
            </div>
            <div className="snowflake">
              <div className="inner">❅</div>
            </div>
            <div className="snowflake">
              <div className="inner">❅</div>
            </div>
            <div className="snowflake">
              <div className="inner">❅</div>
            </div>
            <div className="snowflake">
              <div className="inner">❅</div>
            </div>
            <div className="snowflake">
              <div className="inner">❅</div>
            </div>
            <div className="snowflake">
              <div className="inner">❅</div>
            </div>
            <div className="snowflake">
              <div className="inner">❅</div>
            </div>
            <div className="snowflake">
              <div className="inner">❅</div>
            </div>
            <div className="snowflake">
              <div className="inner">❅</div>
            </div>
            <div className="snowflake">
              <div className="inner">❅</div>
            </div>
          </div>
        }

      </div >
    </div >
  )
}

export default ChatPage