import React from 'react'
import Box from './Box'
import axios from 'axios'

const {
    REACT_APP_DATABASE_BASEURL_DEV,
    REACT_APP_DATABASE_BASEURL,
    REACT_APP_NODE_ENV
} = process.env;

const AddNews = ({
    generateHeaderCloseIco,
    socket,
    setType,
    modalTitle,
    me,
    getNews
}) => {

    const maxLengths = {
        Naslov: 30,
        Podnaslov: 100,
        Opis: 1200,
        Izvor: 300,
        Slika: 300,
        Potpis: 20
    }

    const minLenghts = {
        Naslov: 10,
        Podnaslov: 20,
        Opis: 100,
        Izvor: 6,
        Slika: 6,
        Potpis: 6
    }

    const newNewsDefaults = {
        author: me.name,
        authorDesc: '',
        logo: me.avatar,
        title: '',
        sub: '',
        meta: [{
            link: '',
            text: ''
        }],
        source: '',
        image: '',
        comments: 0,
        lastCommentator: ''
    }

    const inputColorsDefault = {
        Naslov: '',
        Podnaslov: '',
        Opis: '',
        Slika: '',
        Izvor: '',
        Potpis: ''
    }

    const inputLabelsDefault = {
        Naslov: '',
        Podnaslov: '',
        Opis: '',
        Slika: '',
        Izvor: '',
        Potpis: ''
    }

    const [newNews, setNewNews] = React.useState(newNewsDefaults)
    const [inputColors, setInputColors] = React.useState(inputColorsDefault)
    const [inputLabels, setInputLabels] = React.useState(inputLabelsDefault)
    const [checkBox, setCheckBox] = React.useState(false)
    const [infoMessage, setInfoMessage] = React.useState('')
    const [btnDisabled, setBtnDisabled] = React.useState({
        Naslov: true,
        Podnaslov: true,
        Opis: true,
        Slika: true,
        Izvor: true,
        Potpis: true
    })
    const [isEditing, setIsEditing] = React.useState(false)

    const resetAllFields = () => {
        setInputLabels(inputLabelsDefault)
        setInputColors(inputColorsDefault)
        setNewNews(newNewsDefaults)
        setBtnDisabled({
            Naslov: true,
            Podnaslov: true,
            Opis: true,
            Slika: true,
            Izvor: true,
            Potpis: true
        })
    }

    const handleDeleteNews = async () => {
        try {
            const response = await axios.delete(`${REACT_APP_NODE_ENV === 'development' ?
                REACT_APP_DATABASE_BASEURL_DEV :
                REACT_APP_DATABASE_BASEURL}/delete-news/${newNews.id}`)

            if (response.data.status === 1) {
                resetAllFields()
                setInfoMessage(response.data.message)
                getNews()
            } else {
                setInfoMessage(response.data.message)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const getMyNews = async () => {
        try {
            const response = await axios.get(`${REACT_APP_NODE_ENV === 'development' ?
                REACT_APP_DATABASE_BASEURL_DEV :
                REACT_APP_DATABASE_BASEURL}/news-author/${me.name}`)

            if (response.data.status === 1) {
                setNewNews(response.data.news)
                setInputColors({
                    Naslov: 'orange',
                    Podnaslov: 'orange',
                    Opis: 'orange',
                    Slika: 'orange',
                    Izvor: 'orange',
                    Potpis: 'orange'
                })
                setInputLabels({
                    Naslov: 'Uredite naslov',
                    Podnaslov: 'Uredite podnaslov',
                    Opis: 'Uredite opis',
                    Slika: 'Linkajte drugu sliku',
                    Izvor: 'Linkajte drugi izvor',
                    Potpis: 'Uredite potpis'
                })
                setBtnDisabled({
                    Naslov: false,
                    Podnaslov: false,
                    Opis: false,
                    Slika: false,
                    Izvor: false,
                    Potpis: false
                })
                setIsEditing(true)
            }
        } catch (e) {
            console.log(e)
        }
    }

    React.useEffect(() => {
        getMyNews()
    }, [])

    const notifyAll = async () => {
        try {
            const response = await axios.post(`${REACT_APP_NODE_ENV === 'development' ?
                REACT_APP_DATABASE_BASEURL_DEV :
                REACT_APP_DATABASE_BASEURL}/notify-all-news`, {
                    msgToSnd: `${newNews.author} je dodao novu vijest: ${newNews.title}`
                })
            setInfoMessage(response.data.message)
            if (response.data.status === 1) {
                resetAllFields()
                getNews()
            }
        } catch (e) {
            console.log(e)
        }
    }

    const addNews = async () => {
        try {
            const response = await axios.post(`${REACT_APP_NODE_ENV === 'development' ?
                REACT_APP_DATABASE_BASEURL_DEV :
                REACT_APP_DATABASE_BASEURL}/single-news`, newNews)
            setInfoMessage(response.data.message)
            if (response.data.status === 1) {
                socket.emit('notify-all-news', {
                    msgToSnd: `${newNews.author} je dodao novu vijest: ${newNews.title}`
                })
                resetAllFields()
                getNews()
            }
        } catch (e) {
            console.log(e)
        }
    }

    const updateNews = async () => {
        try {
            const response = await axios.put(`${REACT_APP_NODE_ENV === 'development' ?
                REACT_APP_DATABASE_BASEURL_DEV :
                REACT_APP_DATABASE_BASEURL}/update-news/${newNews.id}`, newNews)
            setInfoMessage(response.data.message)
        } catch (e) {
            console.log(e)
        }
    }

    const handleSetCheckbox = () => {
        setCheckBox(!checkBox)
        setInfoMessage('')
    }

    const handleOpenTermOfUse = () => {
        setType('uvjetiKoristenja')
    }

    const checkEmptyFields = (inputValue) => {
        if (inputValue.length === 0) {
            return true
        }
        return false
    }

    const checkLink = (inputValue) => {
        const urlPattern = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

        if (urlPattern.test(inputValue)) {
            return true
        }
        return false
    }

    const checkImage = (inputValue) => {
        const imagePattern = /\.(jpg|jpeg|png|webp|avif|gif|svg)$/;

        if (imagePattern.test(inputValue)) {
            return true
        }
        return false
    }

    const checkFields = (inputName, inputValue) => {
        const urlPattern = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
        const imagePattern = /\.(jpg|jpeg|png|webp|avif|gif|svg)$/;

        if (
            inputValue.length <= maxLengths[inputName] &&
            inputValue.length >= minLenghts[inputName]
        ) {
            return true
        }
        return false

    }

    const checkIsBtnDisabled = () => {

        if (
            !checkBox || Object.values(btnDisabled).includes(true)
        ) {
            return true
        }

        return false
    }

    const handleSetBorderColorAndLabel = (inputName, inputValue) => {

        let inputBorderColor = ''
        let inputLabel = ``

        if (checkFields(inputName, inputValue)) {
            inputBorderColor = 'mediumaquamarine'
            inputLabel = `Odlično. ${inputName} ne smije prelaziti ${maxLengths[inputName]} znakova`
            setBtnDisabled({ ...btnDisabled, [inputName]: false })
        } else if (!checkFields(inputName, inputValue)) {
            inputBorderColor = 'orange'
            inputLabel = `${inputName} mora imati minimalno ${minLenghts[inputName]} znakova`
            setBtnDisabled({ ...btnDisabled, [inputName]: true })
        }

        if (inputName === 'Izvor' && checkLink(inputValue)) {
            inputBorderColor = 'mediumaquamarine'
            inputLabel = 'Link na izvor je dobar'
            setBtnDisabled({ ...btnDisabled, [inputName]: false })
        } else if (inputName === 'Izvor' && !checkLink(inputValue)) {
            inputBorderColor = 'orange'
            inputLabel = 'Link na izvor nije dobar'
            setBtnDisabled({ ...btnDisabled, [inputName]: true })
        }

        if (inputName === 'Slika' && checkImage(inputValue)) {
            inputBorderColor = 'mediumaquamarine'
            inputLabel = 'Link na sliku je dobar'
            setBtnDisabled({ ...btnDisabled, [inputName]: false })
        } else if (inputName === 'Slika' && !checkImage(inputValue)) {
            inputBorderColor = 'orange'
            inputLabel = 'Link na sliku nije dobar'
            setBtnDisabled({ ...btnDisabled, [inputName]: true })
        }

        if (checkEmptyFields(inputValue)) {
            inputBorderColor = ''
            inputLabel = `Unesite ${inputName.toLowerCase()}`
            setBtnDisabled({ ...btnDisabled, [inputName]: true })
        }

        setInputColors({ ...inputColors, [inputName]: inputBorderColor })
        setInputLabels({ ...inputLabels, [inputName]: inputLabel })
    }

    const handleTitleChange = (e) => {
        setNewNews({ ...newNews, title: e.target.value })
        handleSetBorderColorAndLabel('Naslov', e.target.value)
        setInfoMessage('')
    }

    const handleSubChange = (e) => {
        setNewNews({ ...newNews, sub: e.target.value })
        handleSetBorderColorAndLabel('Podnaslov', e.target.value)
        setInfoMessage('')
    }

    const handleSourceChange = (e) => {
        setNewNews({ ...newNews, source: e.target.value })
        handleSetBorderColorAndLabel('Izvor', e.target.value)
        setInfoMessage('')
    }

    const handleImageChange = (e) => {
        setNewNews({ ...newNews, image: e.target.value })
        handleSetBorderColorAndLabel('Slika', e.target.value)
        setInfoMessage('')
    }

    const handleMetaChange = (e) => {
        setNewNews({ ...newNews, meta: [{ link: '', text: e.target.value }] })
        handleSetBorderColorAndLabel('Opis', e.target.value)
        setInfoMessage('')
    }

    const handleAuthorDescChange = (e) => {
        setNewNews({ ...newNews, authorDesc: e.target.value })
        handleSetBorderColorAndLabel('Potpis', e.target.value)
        setInfoMessage('')
    }

    const generateAddNewNewsForm = () => {
        return (
            <div className="column p-right-20">
                {isEditing && <p className="form-p margin-bottom" >Ako želite objavit novu vijest obrišite postojeću. Vijest će se sama obrisati nakon 2 tjedna.</p>}
                {!isEditing && <p className="form-p margin-bottom" >Unesena vijest će se sama obrisati nakon 2 tjedna.</p>}
                <div className="form-item margin-bottom">
                    <input
                        style={{ borderColor: inputColors.Naslov }}
                        value={newNews.title}
                        maxLength={maxLengths.Naslov}
                        minLength={minLenghts.Naslov}
                        onChange={handleTitleChange}
                        className="mm-input w100"
                        id="Naslov"
                        name="Naslov"
                        placeholder="Unesi naslov"
                    />
                    {newNews.title.length !== 0 && <label className="fw200 faded f12 margin-top-s" htmlFor="Naslov">{inputLabels.Naslov}</label>}
                </div>

                <div className="form-item margin-bottom ">
                    <input
                        style={{ borderColor: inputColors.Podnaslov }}
                        value={newNews.sub}
                        maxLength={maxLengths.Podnaslov}
                        minLength={minLenghts.Podnaslov}
                        onChange={handleSubChange}
                        className="mm-input w100"
                        id="Podnaslov"
                        name="Podnaslov"
                        placeholder='Unesi podnaslov'
                    />
                    {newNews.sub.length !== 0 && <label className="fw200 faded f12 margin-top-s" htmlFor="Podnaslov">{inputLabels.Podnaslov}</label>}
                </div>

                <div className="form-item margin-bottom">
                    <textarea
                        style={{ borderColor: inputColors.Opis }}
                        value={newNews.meta[0].text}
                        maxLength={maxLengths.Opis}
                        minLength={minLenghts.Opis}
                        onChange={handleMetaChange}
                        className="mm-input w100"
                        id="Opis"
                        name="Opis"
                        placeholder='Unesi opis'
                    />
                    {newNews.meta[0].text.length !== 0 && <label className="fw200 faded f12 margin-top-s" htmlFor="Opis">{inputLabels.Opis}</label>}
                </div>

                <div className="form-item margin-bottom">
                    <input
                        type="url"
                        style={{ borderColor: inputColors.Izvor }}
                        value={newNews.source}
                        maxLength={maxLengths.Izvor}
                        minLength={minLenghts.Izvor}
                        onChange={handleSourceChange}
                        className="mm-input w100"
                        id="Izvor"
                        name="Izvor"
                        placeholder='Unesi link na izvor'
                    />
                    {newNews.source.length !== 0 && <label className="fw200 faded f12 margin-top-s" htmlFor="Izvor">{inputLabels.Izvor}</label>}
                </div>

                <div className="form-item margin-bottom">
                    <input
                        type="url"
                        style={{ borderColor: inputColors.Slika }}
                        value={newNews.image}
                        maxLength={maxLengths.Slika}
                        minLength={minLenghts.Slika}
                        onChange={handleImageChange}
                        className="mm-input w100"
                        id="Slika"
                        name="Slika"
                        placeholder='Unesi link na sliku.'
                    />
                    {newNews.image.length !== 0 && <label className="fw200 faded f12 margin-top-s" htmlFor="what">{inputLabels.Slika}</label>}
                    <a className="white form-p margin-top-5" target="_blank" href="https://postimg.cc/">Uplodiraj sliku (nakon uploada na PostImg servisu odaberite direktni link)</a>
                </div>

                <div className="form-item margin-bottom ">
                    <input
                        style={{ borderColor: inputColors.Potpis }}
                        value={newNews.authorDesc}
                        maxLength={maxLengths.Potpis}
                        minLength={minLenghts.Potpis}
                        onChange={handleAuthorDescChange}
                        className="mm-input w100"
                        id="Potpis"
                        name="Potpis"
                        placeholder='Unesi potpis/nesto o sebi'
                    />
                    {newNews.authorDesc.length !== 0 && <label className="fw200 faded f12 margin-top-s" htmlFor="Potpis">{inputLabels.Potpis}</label>}
                </div>

                <div className="margin-top checkbox-wrap margin-bottom">
                    <input
                        type="checkbox"
                        id="checkbox"
                        name="checkbox"
                        checked={checkBox}
                        onChange={handleSetCheckbox}
                    />
                    <label htmlFor="checkbox" className="fw200 margin-left form-p noselect">Pročitao sam <span onClick={handleOpenTermOfUse}>uvodnik autora</span></label>
                </div>
                {
                    isEditing ?
                        <div className="row middle margin-bottom">
                            <button disabled={checkIsBtnDisabled()} onClick={updateNews} className="content-button w30">Ažuriraj novost</button>
                            <p onClick={handleDeleteNews} className="form-p pointer margin-left orange" >Obriši novost</p>
                            <p className="form-p margin-left mediumaquamarine" >{infoMessage}</p>
                        </div>
                        :
                        <div className="row middle margin-bottom">
                            <button disabled={checkIsBtnDisabled()} onClick={addNews} className="content-button w30">Dodaj novost</button>
                            <p className="form-p margin-left mediumaquamarine" >{infoMessage}</p>
                        </div>
                }
            </div>
        )
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title">
                    <div className="pop-up__title__content">
                        {modalTitle}
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    <div className="margin-right margin-top-m">
                    <Box
                        isInModal={false}
                        news={newNews}
                        showBtn={false} />
                    </div>     
                    {generateAddNewNewsForm()}
                </div>

            </div>
        </div>
    )
}

export default AddNews