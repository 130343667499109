import React from 'react'

const PlayListModal = ({
    modalTitle,
    socket,
    generateHeaderCloseIco,
    songs,
    currUser,
    getSongs
}) => {

    React.useEffect(() => {
        socket.on('updateSongs', () => {
            getSongs()
        })
    }, [socket])

    const renderSongs = () => {

        const handleDeleteSong = (author) => {
            socket.emit('deleteSong', author)
        }

        return songs.toReversed().map((song, index) => {
            if (currUser === 'TheCreator' || currUser === 'Superadmin') {
                return (
                    <div key={`${Math.random()}${song}`} className="relative w95">
                        <span 
                        onClick={() => {handleDeleteSong(song.author)}}
                        className="notification-number logout-ico pointer">x</span>
                        <div className="row">
                            <p className="margin-right">{`${index + 1}.`}</p>
                            <p>{`${song.name}`}</p>
                        </div>
                    </div>
                )
            }

            return <div key={`${Math.random()}${song.name}`} className="row">
                <p className="margin-right">{`${index + 1}.`}</p>
                <p>{`${song.name}`}</p>
            </div>
        })
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title">
                    <div className="pop-up__title__content">
                        {modalTitle}
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    {renderSongs()}
                </div>
            </div>
        </div>
    )

}

export default PlayListModal