import React from 'react'
import axios from 'axios'

const {
    REACT_APP_DATABASE_BASEURL_DEV,
    REACT_APP_DATABASE_BASEURL,
    REACT_APP_NODE_ENV
} = process.env;

const getAgeFromDate = (ageToFormat) => {
    const givenDate = new Date(ageToFormat);
    const currentDate = new Date();
    const timeDiff = currentDate - givenDate;
    const years = timeDiff / (1000 * 60 * 60 * 24 * 365.25);
    return Math.round(years)
}

const generateGender = (userGender) => {
    if (userGender === 'muško') {
        return 'M'
    } else if (userGender === 'žensko') {
        return 'Ž'
    } else if (userGender === 'nebinaran') {
        return 'N'
    }
}

const MM = ({
    modalTitle,
    generateHeaderCloseIco,
    currUser,
    socket,
    towns,
    setType,
    me
}) => {

    const [gender, setGender] = React.useState('')
    const [genderToFind, setGenderToFind] = React.useState('')
    const [myAge, setMyAge] = React.useState()
    const [ageFrom, setAgeFrom] = React.useState()
    const [ageTo, setAgeTo] = React.useState()
    const [forWhat, setForWhat] = React.useState('')
    const [town, setTown] = React.useState('')
    const [checkBox, setCheckBox] = React.useState(false)
    const [genderToFindBorder, setGenderToFindBorder] = React.useState('')
    const [ageFromBorder, setAgeFromBorder] = React.useState('')
    const [ageToBorder, setAgeToBorder] = React.useState('')
    const [forWhatBorder, setForWhatBorder] = React.useState('')
    const [townBorder, setTownBorder] = React.useState('')
    const [isInMatchmaking, setIsInMatchmaking] = React.useState(false)
    const [info, setInfo] = React.useState('')
    const [infoColor, setInfoColor] = React.useState('')
    const nums = [18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70]
    const lastLocation = React.useRef(null)
    const firstLocation = React.useRef(null)

    React.useEffect(() => {

        const getCurrUserMM = async () => {
            try {
                const response = await axios.get(`${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/mm-one/${currUser}`)

                if (response.data.status === 1) {
                    setIsInMatchmaking(true)
                    setGenderToFind(response.data.mm.genderToFind)
                    setAgeFrom(response.data.mm.ageFrom)
                    setAgeTo(response.data.mm.ageTo)
                    setForWhat(response.data.mm.forWhat)
                    setTown(response.data.mm.town)
                    setCheckBox(false)
                    setGenderToFindBorder('orange')
                    setAgeFromBorder('orange')
                    setAgeToBorder('orange')
                    setForWhatBorder('orange')
                    setTownBorder('orange')
                    setInfo('Izmjenite parametre ili potpuno izađite iz matchmakinga')
                    setInfoColor('orange')

                } else {
                    setIsInMatchmaking(false)
                }
            } catch (e) {

            }
        }

        getCurrUserMM()
    }, [])

    const handleMMUpdate = async () => {

        const MMupdated = {
            author: currUser,
            avatar: me.avatar,
            gender: generateGender(me.gender),
            myAge: getAgeFromDate(me.birthDate),
            genderToFind,
            ageFrom,
            ageTo,
            town,
            forWhat
        }

        try {
            const response = await axios.put(`${REACT_APP_NODE_ENV === 'development' ?
                REACT_APP_DATABASE_BASEURL_DEV :
                REACT_APP_DATABASE_BASEURL}/mm-update`, MMupdated, {
                headers: {
                    'authorization': localStorage.getItem('token')
                }
            })

            if (response.data.status === 1) {
                setInfo('Uspješno ste ažurirali postavke')
                setInfoColor('mediumaquamarine')
                setCheckBox(false)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleForWhatChange = (e) => {
        setForWhat(e.target.value)
        setForWhatBorder('MediumAquaMarine')
        lastLocation.current.scrollIntoView();
    }

    const handleTownsChange = async (e) => {
        setTown(e.target.value)
        setTownBorder('MediumAquaMarine')
    }

    const handleGenderToFindChange = (e) => {
        setGenderToFind(e.target.value)
        setGenderToFindBorder('MediumAquaMarine')
    }

    const handleAgeFromChange = (e) => {
        setAgeFrom(e.target.value)
        setAgeFromBorder('MediumAquaMarine')
    }

    const handleAgeToChange = (e) => {
        setAgeTo(e.target.value)
        setAgeToBorder('MediumAquaMarine')
    }

    const handleLeaveMMNew = () => {
        socket.emit('leaveMMNew', currUser, localStorage.getItem('token'))
        setIsInMatchmaking(false)
        setGenderToFindBorder('')
        setAgeFromBorder('')
        setAgeToBorder('')
        setForWhatBorder('')
        setTownBorder('')
        setGenderToFind('')
        setAgeFrom('')
        setAgeTo('')
        setForWhat('')
        setTown('')
        setCheckBox(false)
        setInfo('Uspješno ste izašli iz matchamakinga.')
        setInfoColor('mediumaquamarine')
        firstLocation.current.scrollIntoView()
    }

    const handleMMNewSubmit = (e) => {
        e.preventDefault()

        const newMMnew = {
            author: currUser,
            avatar: me.avatar,
            gender: generateGender(me.gender),
            myAge: getAgeFromDate(me.birthDate),
            genderToFind,
            ageFrom,
            ageTo,
            town,
            forWhat
        }

        localStorage.setItem('newMMnew', JSON.stringify(newMMnew))
        socket.emit('newMMnew', newMMnew, localStorage.getItem('token'))
        setIsInMatchmaking(true)
        setInfo('Ušli ste u matchmaking! Primit ćete notifikaciju za kompatibilni match.')
        setInfoColor('mediumaquamarine')
        firstLocation.current.scrollIntoView()
    }

    const handleSetCheckbox = () => {
        setCheckBox(!checkBox)
    }

    const handleOpenModal = () => {
        setType('uvjetiKoristenja')
    }

    const checkFields = () => {

        if (
            genderToFind &&
            ageFrom &&
            ageTo &&
            forWhat &&
            town &&
            checkBox
        ) {
            return false
        }

        return true
    }

    const renderMMNew = () => {

        return (
            <div ref={firstLocation}>
                <form onSubmit={handleMMNewSubmit}>
                    {info.length !== 0 && <p style={{ color: infoColor }} className="margin-top margin-right form-p">{info}</p>}
                    <div className="form-wrap">



                        <div className="form-item">
                            <select style={{ borderColor: genderToFindBorder }} value={genderToFind} onChange={handleGenderToFindChange} className="mm-input" id="gender" name="gender">
                                <option disabled selected key="0" value="">Tražim:</option>
                                <option key="Ž" value="Ž">žensko</option>
                                <option key="M" value="M">muško</option>
                                <option key="N" value="N">nebinarnog</option>
                            </select>
                            <label className="fw200 faded f12 margin-top-s" htmlFor="gender">Koga tražiš</label>
                        </div>

                        <div className="form-item">
                        <select style={{ borderColor: forWhatBorder }} onChange={handleForWhatChange} value={forWhat} className="mm-input" id="forWhat" name="forWhat">
                            <option disabled selected key="N" value="">Za:</option>
                            <option key="chat" value="chat">chat</option>
                            <option key="kavu" value="kavu">kavu</option>
                            <option key="odlazak u restoran" value="odlazak u restoran">odlazak u restoran</option>
                            <option key="kino" value="kino">kino</option>
                            <option key="cbd" value="cbd">zapalit CBD joint</option>
                            <option key="izlazak" value="izlazak">izlazak</option>
                            <option key="odlazak na event" value="odlazak na event">odlazak na event</option>
                            <option key="bavljenje sportom" value="bavljenje sportom">bavljenje sportom</option>
                            <option key="bavljenje hobbyem" value="bavljenje hobbyem">bavljenje hobbyem</option>
                            <option key="vezu" value="vezu">vezu</option>
                            <option key="brak" value="brak">brak</option>
                            <option key="sex" value="sex">za odlazak u općinu Fuka na PRC event...</option>
                        </select>
                        <label className="fw200 faded f12 margin-top-s" htmlFor="forWhat">Za koju aktivnost</label>
                    </div>
                    </div>

                   

                    <div className="form-wrap margin-bottom">
                        <div className="form-item">
                            <select style={{ borderColor: ageFromBorder }} value={ageFrom} onChange={handleAgeFromChange} className="mm-input" id="age-from" name="age-from">
                                <option disabled selected key="N" value="">Od:</option>
                                {nums.map(num => <option key={num} value={num}>{`${num} god.`}</option>)}
                            </select>
                            <label className="fw200 faded f12 margin-top-s" htmlFor="age-from">Od koliko</label>
                        </div>

                        <div className="form-item">
                            <select style={{ borderColor: ageToBorder }} value={ageTo} onChange={handleAgeToChange} className="mm-input" id="age-to" name="age-to">
                                <option disabled selected key="N" value="">Do:</option>
                                {nums.map(num => <option key={num} value={num}>{`${num} god.`}</option>)}
                            </select>
                            <label className="fw200 faded f12 margin-top-s" htmlFor="age-to">Do koliko godina</label>
                        </div>

                        <div className="form-item">
                            <select style={{ borderColor: townBorder }} onChange={handleTownsChange} value={town} className="mm-input" id="towns" name="towns">
                                <option disabled selected key="N" value="">U mjestu:</option>
                                {towns.map(town => <option key={town} value={town}>{town}</option>)}
                            </select>
                            <label className="fw200 faded f12 margin-top-s" htmlFor="towns">Odaberi lokaciju</label>
                        </div>
                    </div>



                    <div ref={lastLocation} className="margin-top checkbox-wrap margin-bottom">
                        <input
                            type="checkbox"
                            id="checkbox"
                            name="checkbox"
                            checked={checkBox}
                            onChange={handleSetCheckbox}
                        />
                        <label htmlFor="checkbox" className="fw200 margin-left form-p noselect">Pročitao sam <span onClick={handleOpenModal}>uvodnik autora</span></label>
                    </div>

                    <div className="row middle space-between">
                        {!isInMatchmaking && <button disabled={checkFields()} className="content-button" type="submit">Uđi u matchmaking</button>}
                        {isInMatchmaking && <button onClick={handleMMUpdate} disabled={checkFields()} className="content-button" type="submit">Izmjeni postavke</button>}
                        {isInMatchmaking && <p onClick={handleLeaveMMNew} className="margin-left form-p pointer underline margin-right orange">Izađi iz matchamkinga</p>}
                    </div>

                </form>
            </div>
        )
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title">
                    <div className="pop-up__title__content">
                        {modalTitle}
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    {renderMMNew()}
                </div>

            </div>
        </div>
    )
}

export default MM