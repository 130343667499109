import React from 'react'
import axios from 'axios'

const env = process.env
const {
    REACT_APP_DATABASE_BASEURL_DEV,
    REACT_APP_DATABASE_BASEURL,
    REACT_APP_NODE_ENV,
    REACT_APP_SCRAPER_BASEURL_DEV,
    REACT_APP_SCRAPER_BASEULR
} = process.env;

const Admin = ({
    generateHeaderCloseIco,
    socket,
    banners,
    questBox
}) => {

    const [bannerToAdd, setBannerToAdd] = React.useState({
        logo: '',
        title: '',
        link: ''
    })

    const [photoToAdd, setPhotoToAdd] = React.useState({
        name: '',
        url: '',
        sub: '',
        link: ''
    })
    const [newQuestboxWord, setNewQuestboxWord] = React.useState(``)
    const [newWordsNum, setNewWordsNum] = React.useState(8)
    const [gameDuration, setGameDuration] = React.useState(25000)
    const [multiplayerCountdown, setMultiplayerCountdown] = React.useState(20000)
    const [MPcooldown, setMPcooldown] = React.useState(60000)
    const [newMaxPlayersNum, setNewMaxPlayersNum] = React.useState(4)
    const [spambotInterval, setSpambotInterval] = React.useState(300000)
    const [shoutboxInterval, setShoutboxInterval] = React.useState(60000)
    const [adminScreen, setAdminScreen] = React.useState('banners')
    const [numberOfFightsForSeasonEnd, setNumberOfFightsForSeasonEnd] = React.useState(10)
    const [updateMessage, setUpdateMessage] = React.useState(null)
    const [adminNotifyDesc, setAdminNotifyDesc] = React.useState('')
    const [event, setEvent] = React.useState({
        lokacija: null,
        datum: null,
        vrijeme: null,
        tip: null
    })
    const [bgs, setBgs] = React.useState([])

    const fetchImages = async () => {
        const data = await axios.get(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/photos`)
        setBgs(data.data.photos)
    }

    const handleStartNewsScraper = async () => {
        try {
            const response = await axios.get(`${REACT_APP_NODE_ENV === "development" ? REACT_APP_SCRAPER_BASEURL_DEV : REACT_APP_SCRAPER_BASEULR}/news`)
            setUpdateMessage(response.data.message)
        } catch (e) {
            setUpdateMessage(e.message)
        }
    }

    const handleStartPhotoScraper = async () => {
        try {
            const response = await axios.get(`${REACT_APP_NODE_ENV === "development" ? REACT_APP_SCRAPER_BASEURL_DEV : REACT_APP_SCRAPER_BASEULR}/photos`)
            setUpdateMessage(response.data.message)
        } catch (e) {
            setUpdateMessage(e.message)
        }
    }

    const handleGetNewsReport = async () => {
        try {
            const response = await axios.get(`${REACT_APP_NODE_ENV === "development" ? REACT_APP_SCRAPER_BASEURL_DEV : REACT_APP_SCRAPER_BASEULR}/newsReport`)
            setUpdateMessage(response.data.newsReport)
        } catch (e) {
            setUpdateMessage(e.message)
        }
    }

    const handleGetPhotosReport = async () => {
        try {
            const response = await axios.get(`${REACT_APP_NODE_ENV === "development" ? REACT_APP_SCRAPER_BASEURL_DEV : REACT_APP_SCRAPER_BASEULR}/photosReport`)
            setUpdateMessage(response.data.photosReport)
        } catch (e) {
            setUpdateMessage(e.message)
        }
    }


    const handlePhotoNameChange = (e) => {
        setPhotoToAdd({ ...photoToAdd, name: e.target.value })
    }

    const handlePhotoUrlChange = (e) => {
        setPhotoToAdd({ ...photoToAdd, url: e.target.value })
    }

    const handlePhotoSubChange = (e) => {
        setPhotoToAdd({ ...photoToAdd, sub: e.target.value })
    }

    const handlePhotoLinkChange = (e) => {
        setPhotoToAdd({ ...photoToAdd, link: e.target.value })
    }

    const handleChangeLokacija = (e) => {
        setEvent({ ...event, lokacija: e.target.value })
    }

    const handleChangeDatum = (e) => {
        setEvent({ ...event, datum: e.target.value })
    }

    const handleChangeVrijeme = (e) => {
        setEvent({ ...event, vrijeme: e.target.value })
    }

    const handleChangeTip = (e) => {
        setEvent({ ...event, tip: e.target.value })
    }

    const createEvent = async () => {
        try {
            const response = await axios.post(`${REACT_APP_NODE_ENV === "development" ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/event`, event)
            if (response.data.status === 1) {
                setUpdateMessage(response.data.message)
                handleAdminPMSubmit()
                handleAdminNotifySubmit(`nethr.chat event! ${event.lokacija}, ${event.datum} u ${event.vrijeme} sati. Tip eventa: ${event.tip}. Detalji u privatnoj poruci.`, 'event')
                setEvent({
                    lokacija: '',
                    datum: '',
                    vrijeme: '',
                    tip: ''
                })
                setAdminNotifyDesc('')
            } else {
                setUpdateMessage(response.data.message)
            }
        } catch (e) {
            console.log(e)
        }
    }

    React.useEffect(() => {
        const getOptions = async () => {
            try {
                const data = await axios.get(`${REACT_APP_NODE_ENV === "development" ? REACT_APP_DATABASE_BASEURL_DEV : REACT_APP_DATABASE_BASEURL}/options`)
                const savedOptions = data.data.options
                let options
                for (let i = 0; i < savedOptions.length; i++) {
                    options = { ...options, [savedOptions[i].optionName]: [savedOptions[i].optionValue] }
                }
                setNewWordsNum(options.wordsNum)
                setGameDuration(options.gameDuration)
                setMultiplayerCountdown(options.multiplayerCountdown)
                setMPcooldown(options.MPcooldown)
                setNewMaxPlayersNum(options.maxPlayersNum)
                setSpambotInterval(options.spambotInterval)
                setShoutboxInterval(options.shoutBoxChatNotificationInterval)
                setNumberOfFightsForSeasonEnd(options.numberOfFightsForSeasonEnd)
            } catch (e) {
                console.log(e)
            }
        }

        getOptions()
        fetchImages()
    }, [])

    React.useEffect(() => {
        socket.on('showOptionsSaveMessage', (recevedMsg) => {
            setUpdateMessage(recevedMsg)
        })

        socket.on('updateBgs', () => {
            console.log('testis')
            fetchImages()
        })
    }, [socket])

    const handleAdminNotifyDescChange = (e) => {
        setAdminNotifyDesc(e.target.value)
    }

    const handleAdminNotifySubmit = (text, type) => {
        console.log(type)
        socket.emit('notifyAll', text ? text : adminNotifyDesc, type ? type : 'system')
        setAdminNotifyDesc('')
    }

    const handleAdminPMSubmit = () => {
        socket.emit('PMAll', adminNotifyDesc)
        setAdminNotifyDesc('')
    }

    const handleUpdateOptions = () => {
        socket.emit('updateAllOptions', {
            spambotInterval: Number(spambotInterval),
            multiplayerCountdown: Number(multiplayerCountdown),
            MPcooldown: Number(MPcooldown),
            maxPlayersNum: Number(newMaxPlayersNum),
            gameDuration: Number(gameDuration),
            wordsNum: Number(newWordsNum),
            shoutBoxChatNotificationInterval: Number(shoutboxInterval),
            numberOfFightsForSeasonEnd: Number(numberOfFightsForSeasonEnd)
        })
    }

    const handleRemoveBanner = (idToRemove) => {
        socket.emit('removeBanner', idToRemove)
    }

    const handleAddPhoto = (e) => {
        e.preventDefault()
        socket.emit('addPhoto', photoToAdd)
        setPhotoToAdd({
            name: '',
            url: '',
            sub: '',
            link: ''
        })
    }

    const handleRemoveBg = (idToRemove) => {
        socket.emit('removeBg', idToRemove)
    }

    const handleAddBanner = (e) => {
        e.preventDefault()
        socket.emit('addBanner', bannerToAdd)
        setBannerToAdd({
            logo: '',
            title: '',
            link: ''
        })
    }

    const handleBannerLocationChange = (e) => {
        setBannerToAdd({ ...bannerToAdd, logo: e.target.value })
    }

    const handleBannerTitleChange = (e) => {
        setBannerToAdd({ ...bannerToAdd, title: e.target.value })
    }

    const handleBannerLinkChange = (e) => {
        setBannerToAdd({ ...bannerToAdd, link: e.target.value })
    }

    const handleRemoveQuestWord = (q) => {
        socket.emit('removeQuestWord', q)
    }

    const handleAddNewQuestboxWordChange = (e) => {
        setNewQuestboxWord(e.target.value)
    }

    const handleAddNewQuestboxWord = (e) => {
        e.preventDefault()
        socket.emit('addNewQuestboxWord', newQuestboxWord)
        setNewQuestboxWord(``)
    }

    //fights to end the season
    const handleNumberOfFightsForSeasonEndChange = (e) => {
        setNumberOfFightsForSeasonEnd(e.target.value)
        setUpdateMessage(null)
    }

    const handleWordsNumChange = (e) => {
        setNewWordsNum(e.target.value)
        setUpdateMessage(null)
    }

    const handleGameDurationChange = (e) => {
        setGameDuration(e.target.value)
        setUpdateMessage(null)
    }

    const handleMultiplayerCountdownChange = (e) => {
        setMultiplayerCountdown(e.target.value)
        setUpdateMessage(null)
    }

    const handleMPcooldownChange = (e) => {
        setMPcooldown(e.target.value)
        setUpdateMessage(null)
    }

    const handleSetNewMaxPlayersNumChange = (e) => {
        setNewMaxPlayersNum(e.target.value)
        setUpdateMessage(null)
    }

    const handleSpambotIntervalChange = (e) => {
        setSpambotInterval(e.target.value)
        setUpdateMessage(null)
    }

    const handleShoutboxIntervalChange = (e) => {
        setShoutboxInterval(e.target.value)
        setUpdateMessage(null)
    }

    const handleAdminScreenChange = (e) => {
        setAdminScreen(e.target.value)
        setUpdateMessage(null)
    }

    const generateAdminHeader = () => {
        return (
            <select onChange={handleAdminScreenChange} value={adminScreen} className="mm-input w33" id="forWhat" name="forWhat">
                <option key="banneri" value="banners">Banneri</option>
                <option key="naslovnica" value="naslovnica">Naslovnica</option>
                <option key="mp" value="mp">Multiplayer</option>
                <option key="options" value="options">Postavke</option>
                <option key="notifyAll" value="notifyAll">Notify All</option>
                <option key="msgAll" value="msgAll">Message All</option>
                <option key="event" value="event">Create Event</option>
                <option key="scrapers" value="scrapers">Scrapers</option>
            </select>
        )
    }

    const renderAdmin = () => {
        switch (adminScreen) {
            case 'scrapers':
                const report = updateMessage ? updateMessage.split(',') : []
                return (
                    <div className="p-right-20">
                        <h3>Scrapers</h3>
                        <p className="margin-bottom">Pokreni scrapere</p>
                        <div className="scraper-panel">
                            <div className="scraper-panel-col1">
                                <button onClick={handleStartNewsScraper} className="content-button margin-bottom-10">News scraper</button>
                                <button onClick={handleGetNewsReport} className="content-button button-gray margin-bottom-20">News report</button>
                                <button onClick={handleStartPhotoScraper} className="content-button margin-bottom-10">Photo scrapera</button>
                                <button onClick={handleGetPhotosReport} className="content-button button-gray">Photo report</button>
                            </div>
                            <div className="scraper-panel-col2">
                                {report.map(r => <p className="form-p margin-left mediumaquamarine" >{r}</p>)}
                            </div>
                        </div>
                    </div>
                )
            case 'banners':
                return (
                    <div className="p-right-20">
                        <h3>Banneri</h3>
                        <p className="margin-bottom">Ukloni banner</p>
                        <div className="admin-banners-wrap">
                            {banners.length !== 0 && banners.map(b => {
                                return (
                                    <div key={`${Math.random()}${b.link}`} className="admin-banner-wrap">
                                        <span onClick={() => handleRemoveBanner(b.id)} className="notification-number logout-ico pointer">x</span>
                                        <img className="admin-banner" src={b.logo} />
                                    </div>
                                )
                            })}
                        </div>
                        <p className="margin-top margin-bottom">Dodaj banner</p>
                        <div className="margin-bottom">
                            <form onSubmit={handleAddBanner} className="column">
                                <input onChange={handleBannerLocationChange} value={bannerToAdd.logo} className="margin-bottom mm-input" placeholder="Lokacija bannera (npr. /banners/hyperion.jpg)"></input>
                                <input onChange={handleBannerTitleChange} value={bannerToAdd.title} className="margin-bottom mm-input" placeholder="Naslov, koji će se koristit za alt"></input>
                                <input onChange={handleBannerLinkChange} value={bannerToAdd.link} className="margin-bottom mm-input" placeholder="Link na koji će vodit banner"></input>
                                <button type="submit" className="content-button">Dodaj banner</button>
                            </form>
                        </div>
                    </div>
                )
            case 'naslovnica':
                return (
                    <div className="p-right-20">
                        <h3>Naslovnica</h3>
                        <p className="margin-bottom">Ukloni naslovnu sliku</p>
                        <div className="admin-banners-wrap">
                            {bgs.length !== 0 && bgs.map(b => {
                                return (
                                    <div key={`${Math.random()}${b.link}`} className="admin-banner-wrap">
                                        <span onClick={() => handleRemoveBg(b.id)} className="notification-number logout-ico pointer">x</span>
                                        <img className="admin-bg" src={b.url} />
                                    </div>
                                )
                            })}
                        </div>
                        <p className="margin-top margin-bottom">Dodaj naslovnu sliku</p>
                        <div className="margin-bottom">
                            <form onSubmit={handleAddPhoto} className="column">
                                <input onChange={handlePhotoNameChange} value={photoToAdd.name} className="margin-bottom mm-input" placeholder="Ime izvora"></input>
                                <input onChange={handlePhotoUrlChange} value={photoToAdd.url} className="margin-bottom mm-input" placeholder="Link na sliku"></input>
                                <input onChange={handlePhotoSubChange} value={photoToAdd.sub} className="margin-bottom mm-input" placeholder="Opis"></input>
                                <input onChange={handlePhotoLinkChange} value={photoToAdd.link} className="margin-bottom mm-input" placeholder="Link na izvor"></input>
                                <button type="submit" className="content-button">Dodaj sliku</button>
                            </form>
                        </div>
                    </div>
                )
            case 'mp':
                return (
                    <div className="p-right-20">

                        <h3 className="margin-top margin-bottom">Multiplayer riječi</h3>
                        <div className="questbox-wrap margin-bottom">
                            {questBox.length !== 0 && questBox.map(q => {
                                return (
                                    <div key={`${Math.random()}${q}`} className="questbox-item">
                                        <span onClick={() => { handleRemoveQuestWord(q) }} className="notification-number logout-ico pointer">x</span>
                                        {q}
                                    </div>
                                )
                            })}
                        </div>
                        <p className="margin-top margin-bottom">Dodaj multiplayer riječ</p>
                        <form onSubmit={handleAddNewQuestboxWord} className="margin-bottom">
                            <div className="row middle">
                                <input className="mm-input margin-right" onChange={handleAddNewQuestboxWordChange} value={newQuestboxWord} placeholder="Upiši riječ, npr. Bolognese"></input>
                                <button className="content-button margin-left" type="submit">Dodaj</button>
                            </div>
                        </form>
                    </div>
                )
            case 'options':
                return (
                    <div className="p-right-20">
                        <h3 className="margin-top margin-bottom">Uredi postavke</h3>
                        <div className="row multiline space-between">
                            <div className="w100 margin-bottom row middle">
                                <label className="w70 p-right-20">Broj riječi u questu</label>
                                <input className="w30 mm-input" type="number" onChange={handleWordsNumChange} value={newWordsNum} placeholder="Defaultno: 25000"></input>
                            </div>
                            <div className="w100 margin-bottom row middle">
                                <label className="w70 p-right-20">Trajanje multiplayera (u milisekundama)</label>
                                <input className="w30 mm-input" type="number" onChange={handleGameDurationChange} step="500" value={gameDuration} placeholder="Defaultno: 25000"></input>
                            </div>
                            <div className="w100 margin-bottom row middle">
                                <label className="w70 p-right-20">Multiplayer countdown (u milisekundama)</label>
                                <input className="w30 mm-input" type="number" onChange={handleMultiplayerCountdownChange} step="500" value={multiplayerCountdown} placeholder="Defaultno: 20000"></input>
                            </div>
                            <div className="w100 margin-bottom row middle">
                                <label className="w70 p-right-20">Multiplayer cooldown (u milisekundama)</label>
                                <input className="w30 mm-input" type="number" onChange={handleMPcooldownChange} step="500" value={MPcooldown} placeholder="Defaultno: 20000"></input>
                            </div>
                            <div className="w100 margin-bottom row middle">
                                <label className="w70 p-right-20">Max players</label>
                                <input className="w30 mm-input" type="number" onChange={handleSetNewMaxPlayersNumChange} value={newMaxPlayersNum} placeholder="Defaultno: 4"></input>
                            </div>
                            <div className="w100 margin-bottom row middle">
                                <label className="w70 p-right-20">Pušaj vijest svako koliko poruka</label>
                                <input className="w30 mm-input" type="number" onChange={handleSpambotIntervalChange} value={spambotInterval} placeholder="Defaultno: 300000/5 min"></input>
                            </div>
                            <div className="w100 margin-bottom row middle">
                                <label className="w70 p-right-20">Pušaj shout svako koliko poruka</label>
                                <input className="w30 mm-input" type="number" onChange={handleShoutboxIntervalChange} value={shoutboxInterval} placeholder="Defaultno: 60000/1 min"></input>
                            </div>
                            <div className="w100 margin-bottom row middle">
                                <label className="w70 p-right-20">Number of fight to end season (shield and sword)</label>
                                <input className="w30 mm-input" type="number" onChange={handleNumberOfFightsForSeasonEndChange} value={numberOfFightsForSeasonEnd} placeholder="Defaultno: 10"></input>
                            </div>
                            <div className="row middle margin-top margin-bottom">
                                <button onClick={handleUpdateOptions} className="content-button">Ažuriraj opcije</button>
                                <p className="form-p margin-left mediumaquamarine" >{updateMessage}</p>
                            </div>
                        </div>
                    </div>
                )
            case 'notifyAll':
                return (
                    <div className="p-right-20">
                        <h3 className="margin-top margin-bottom">Notificiraj sve korisnike</h3>
                        <div>
                            <textarea
                                rows="5"
                                value={adminNotifyDesc}
                                onChange={handleAdminNotifyDescChange}
                                className="mm-input w100"
                                id="desc"
                                name="desc"
                                placeholder={`Unesi tekst`}
                            />
                            <div className="row middle margin-top margin-bottom">
                                <button onClick={handleAdminNotifySubmit} className="content-button margin-top">Pošalji</button>
                                <p className="form-p margin-left mediumaquamarine" >{updateMessage}</p>
                            </div>

                        </div>

                    </div>
                )
            case 'msgAll':
                return (
                    <div className="p-right-20">
                        <h3 className="margin-top margin-bottom">Pošalji privatnu poruku svim korisnicima</h3>
                        <div>
                            <textarea
                                rows="5"
                                value={adminNotifyDesc}
                                onChange={handleAdminNotifyDescChange}
                                className="mm-input w100"
                                id="desc"
                                name="desc"
                                placeholder={`Unesi tekst`}
                            />
                            <div className="row middle margin-top margin-bottom">
                                <button onClick={handleAdminPMSubmit} className="content-button margin-top">Pošalji</button>
                                <p className="form-p margin-left mediumaquamarine" >{updateMessage}</p>
                            </div>
                        </div>
                    </div>
                )
            case 'event':
                return (
                    <div className="p-right-20">
                        <h3 className="margin-top margin-bottom">Kreiraj event</h3>
                        <div className="w100 margin-bottom row middle">
                            <label className="w70 p-right-20">Lokacija</label>
                            <input className="w30 mm-input" type="text" onChange={handleChangeLokacija} value={event.lokacija} placeholder="npr. Papaya, Zrče beach"></input>
                        </div>
                        <div className="w100 margin-bottom row middle">
                            <label className="w70 p-right-20">Datum</label>
                            <input className="w30 mm-input" type="date" onChange={handleChangeDatum} value={event.datum} placeholder="25.12.2023."></input>
                        </div>
                        <div className="w100 margin-bottom row middle">
                            <label className="w70 p-right-20">Vrijeme</label>
                            <input className="w30 mm-input" type="text" onChange={handleChangeVrijeme} value={event.vrijeme} placeholder="20:30"></input>
                        </div>
                        <div className="w100 margin-bottom row middle">
                            <label className="w70 p-right-20">Tip</label>
                            <input className="w30 mm-input" type="text" onChange={handleChangeTip} value={event.tip} placeholder="Tech/house themed party"></input>
                        </div>
                        <div>
                            <textarea
                                rows="5"
                                value={adminNotifyDesc}
                                onChange={handleAdminNotifyDescChange}
                                className="mm-input w100"
                                id="desc"
                                name="desc"
                                placeholder={`Unesi privatnu poruku za korisnike`}
                            />
                            <div className="row middle margin-top margin-bottom">
                                <button onClick={createEvent} className="content-button margin-top">Pošalji</button>
                                <p className="form-p margin-left mediumaquamarine" >{updateMessage}</p>
                            </div>
                        </div>
                    </div>
                )
        }
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title">
                    <div className="pop-up__title__content">
                        {generateAdminHeader()}
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    {renderAdmin()}
                </div>

            </div>
        </div>
    )
}

export default Admin