import React from 'react'
import axios from 'axios'
import SeasonBox from './SeasonBox'

const {
    REACT_APP_DATABASE_BASEURL_DEV,
    REACT_APP_DATABASE_BASEURL,
    REACT_APP_NODE_ENV
} = process.env;

const SeasonsModal = ({
    modalTitle,
    generateHeaderCloseIco
}) => {

    const [seasonsList, setSeasonsList] = React.useState(null)

    React.useEffect(() => {
        const getSeasonsList = async () => {
            try {
                const response = await axios.get(
                    `${REACT_APP_NODE_ENV === 'development' ?
                        REACT_APP_DATABASE_BASEURL_DEV :
                        REACT_APP_DATABASE_BASEURL}/seasons`)

                if (response.data.status === 1) {
                    setSeasonsList(response.data.season)
                }        

            } catch (e) {

            }
        }

        getSeasonsList()
    },[])

    const renderSeasons = () => {
        return seasonsList.map(season => {
            return <SeasonBox season={season} showBtn={false}/>
        })
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title">
                    <div className="pop-up__title__content">
                        {modalTitle}
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    {seasonsList && renderSeasons()}
                </div>
            </div>
        </div>
    )
}

export default SeasonsModal