import React from 'react'
import GameBox from './GameBox'
import axios from 'axios'

const {
    REACT_APP_EVENTS_FOLDER,
    REACT_APP_EVENTS_FOLDER_DEV,
    REACT_APP_NODE_ENV,
    REACT_APP_SERVER_BASEURL_DEV,
    REACT_APP_SERVER_BASEULR
} = process.env

const EventsModal = ({
    modalTitle,
    generateHeaderCloseIco,
    socket,
    currEvent,
    formatEventDate
}) => {

    const [events, setEvents] = React.useState(null)
    const [currFolder, setCurrFolder] = React.useState(null)
    const [currFilesCount, setCurrFilesCount] = React.useState(null)

    const getEvents = async () => {
        console.log(REACT_APP_EVENTS_FOLDER,
            REACT_APP_EVENTS_FOLDER_DEV,
            REACT_APP_NODE_ENV)
        try {
            const response = await axios.get(REACT_APP_NODE_ENV === 'development' ? REACT_APP_EVENTS_FOLDER_DEV : REACT_APP_EVENTS_FOLDER)
            setEvents(response.data)
        } catch (e) {
            console.log(e)
        }
    }

    React.useEffect(() => {
        getEvents()
    }, [])

    const handleCurrFolderChange = (e) => {
        setCurrFolder(e.target.value)
        const currFolderIndex = events.findIndex(ev => ev.folderName === e.target.value)
        setCurrFilesCount(events[currFolderIndex].fileCount)
    }


    const renderEventBox = () => {
        if (currEvent && Object.keys(currEvent).length !== 0) {
            return (
                <div className="p-right-20">
                    <GameBox title='nethr.chat party!' sub={`${currEvent.lokacija}, ${formatEventDate()} u ${currEvent.vrijeme}h`} sub2={currEvent.tip} sub3="(detalji u privatnoj poruci)" btnText="empty" imgSrc="party.png" socket={socket} />
                </div>
            )
        }
    }

    const renderGalleryHeader = () => {
        if (!events) {
            return    
        }

        return (
            <div className="p-right-20 margin-bottom">
                <div className="column w50">
                    <select
                        value={currFolder}
                        onChange={handleCurrFolderChange}
                        className="mm-input"
                        id="currFolder"
                        name="currFolder"
                        style={{ borderColor: 'mediumaquamarine' }}
                    >
                        <option disabled selected key="tip" value="">Pogledaj prethodne evente:</option>
                        {events.map(event => {
                            return <option key={event.folderName} value={event.folderName}>{event.folderName}</option>
                        })}
                    </select>
                    <label className="faded f12 margin-top-s fw200" htmlFor="currFolder">Pogledaj slike sa prethodnih evenata</label>
                </div>
            </div>
        )
    }

    const renderGallery = () => {
        if (!events) {
            return 
        }

        const images = []
        
        for(let i=1;i <= currFilesCount;i++) {
            images.push(<img className="margin-bottom event-gal-item" src={`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_SERVER_BASEURL_DEV : REACT_APP_SERVER_BASEULR}/media/events/${currFolder}/${i}.webp`}/>) 
        }
        

        return (
            <div className="column p-right-20">
                {images.map(image => image)}
            </div>
        )
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title">
                    <div className="pop-up__title__content">
                        {modalTitle}
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    {renderEventBox()}
                    {renderGalleryHeader()}
                    {renderGallery()}
                    <img src="" />
                </div>

            </div>
        </div>
    )

}

export default EventsModal