import React from 'react'
import axios from 'axios'

const {
    REACT_APP_DATABASE_BASEURL_DEV,
    REACT_APP_DATABASE_BASEURL,
    REACT_APP_NODE_ENV
} = process.env;

const ShoutsQueue = ({
    generateHeaderCloseIco,
    currUser,
    towns,
    setType,
    setMyShout,
    socket,
    me
}) => {

    const [mm, setMM] = React.useState([])
    const [addType, setAddType] = React.useState('')
    const [addCat, setAddCat] = React.useState('')
    const [tipLabel, setTipLabel] = React.useState('Vrsta shoutbota')
    const [catLabel, setCatLabel] = React.useState('Kategorija shoutbota')
    const [locLabel, setLocLabel] = React.useState('Lokacija shoutbota')
    const [tipBorder, setTipBorder] = React.useState('')
    const [catBorder, setCatBorder] = React.useState('')
    const [locBorder, setLocBorder] = React.useState('')
    const [shoutQueueInfo, setShoutQueueInfo] = React.useState('')
    const [currOffset, setCurrOffset] = React.useState(0)
    const [shoutResultsCount, setShoutResultsCount] = React.useState(0)
    const shoutResultsLimit = 10
    const [isResetFiltersVisible, setIsResetFilterVisible] = React.useState(false)
    const [currUserShoutCount, setCurrUserShoutCount] = React.useState(0)
    const [searchBorder, setSearchBorder] = React.useState('')
    const [searchLabel, setSearchLabel] = React.useState('Što tražite?')
    const [searchTerm, setSearchTerm] = React.useState('')
    const [town, setTown] = React.useState('')
    const [what, setWhat] = React.useState('')

    React.useEffect(() => {
        getShouts({}, currOffset)
        getCurrUserShoutCount(currUser)
    }, [])

    const resetFilters = () => {
        setTipBorder('')
        setCatBorder('')
        setLocBorder('')
        setAddType('')
        setAddCat('')
        setTown('')
        setSearchBorder('')
        setSearchLabel('Unesite ključnu frazu')
        setSearchTerm('')
        setIsResetFilterVisible(false)
        getShouts({}, 0)
        setCurrOffset(0)
    }

    const notifyAuthor = async (id, author, notificationText) => {

        try {
            const response = await axios.put(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/shoutbox-reacted/${id}`,
                {
                    user: currUser
                }
            )

            if (response.data.status === 1) {
                const avatar = me.avatar
                socket.emit('updateShoutNotification', { author, notificationText, currUser, avatar })
                getShouts({}, currOffset)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const handleSetMM = () => {
        resetAllFields()
        setType('matchmaking')
    }

    const handleEditMM = (id) => {
        const shoutToEdit = mm.find(shouts => shouts.id === id)
        setMyShout(shoutToEdit)
        setType('matchmaking')
    }

    const handleTownsChange = async (e) => {
        setTown(e.target.value)
        setLocBorder(e.target.value === 'All' ? 'orange' : 'MediumAquaMarine')
        setLocLabel('Lokacija')
        setIsResetFilterVisible(true)
        getShouts({
            [e.target.value === 'All' ? undefined : 'town']: e.target.value === 'All' ? undefined : e.target.value,
            [addCat.length === 0 || addCat === 'All' ? undefined : 'addCat']: addCat.length === 0 || addCat === 'All' ? undefined : addCat,
            [addType.length === 0 || addType === 'All' ? undefined : 'addType']: addType.length === 0 || addType === 'All' ? undefined : addType
        }, currOffset)
    }

    const getShouts = async (filterConfig, offset) => {

        try {
            const response = await axios.post(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/shoutbox-filter/${offset}/${shoutResultsLimit}`,
                filterConfig
            )

            if (response.data.status === 1) {
                setMM(response.data.data.rows)
                setShoutResultsCount(response.data.data.count)
                setShoutQueueInfo(`${response.data.data.count} shouta`)
            }
        } catch (e) {
            setShoutQueueInfo(`Error: (${e}).`)
        }
    }

    const searchShouts = async (term, offset) => {

        try {
            const response = await axios.post(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/shoutbox-search/${offset}/${shoutResultsLimit}`,
                {
                    term
                }
            )

            if (response.data.status === 1) {
                setMM(response.data.data.rows)
                setShoutResultsCount(response.data.data.count)
                setShoutQueueInfo(`${response.data.data.count} shouta`)
            }
        } catch (e) {
            setShoutQueueInfo(`Error: (${e}).`)
        }
    }

    const resetAllFields = () => {
        setAddType('')
        setAddCat('')
        setTown('')
        setWhat('')
        setTipBorder('white')
        setCatBorder('white')
        setLocBorder('white')
        setTipLabel('Vrsta shoutbota')
        setCatLabel('Kategorija shoutbota')
        setLocLabel('Lokacija shoutbota')
        setSearchBorder('')
        setSearchLabel('Unesite ključnu frazu')
    }

    const handleGetMyShouts = () => {
        getShouts({
            author: currUser
        }, 0)
        resetAllFields()
        setIsResetFilterVisible(true)
    }

    const handleSearchChange = (e) => {

        setSearchTerm(e.target.value)

        if (e.target.value.length === 0) {
            setIsResetFilterVisible(false)
            setSearchBorder('')
            setSearchLabel('Unesite ključnu frazu')
            resetFilters()
        } else if (e.target.value.length < 3) {
            setSearchBorder('orange')
            setSearchLabel('Minimalno 3 max. 20 znakova')
        } else {
            resetAllFields()
            setSearchBorder('MediumAquaMarine')
            setSearchLabel('Što tražite?')
            setIsResetFilterVisible(true)
            searchShouts(e.target.value, 0)
        }
    }

    const getCurrUserShoutCount = async (currUser) => {
        try {
            const response = await axios.get(`${REACT_APP_NODE_ENV === 'development' ?
                REACT_APP_DATABASE_BASEURL_DEV :
                REACT_APP_DATABASE_BASEURL}/shoutbox-count/${currUser}`)
            setCurrUserShoutCount(response.data.data)
        } catch (e) {
            console.log(e)
        }
    }

    const handleChangeOffsetPositive = () => {
        setCurrOffset(currOffset + shoutResultsLimit)
        getShouts({
            [addType.length === 0 || addType === 'All' ? undefined : 'addType']: addType.length === 0 || addType === 'All' ? undefined : addType,
            [addCat.length === 0 || addType === 'All' ? undefined : 'addCat']: addCat.length === 0 || addCat === 'All' ? undefined : addCat,
            [town.length === 0 || town === 'All' ? undefined : 'town']: town.length === 0 || town === 'All' ? undefined : town
        }, currOffset + shoutResultsLimit)
    }

    const handleChangeOffsetNegative = () => {
        setCurrOffset(currOffset - shoutResultsLimit)
        getShouts({
            [addType.length === 0 || addType === 'All' ? undefined : 'addType']: addType.length === 0 || addType === 'All' ? undefined : addType,
            [addCat.length === 0 || addType === 'All' ? undefined : 'addCat']: addCat.length === 0 || addCat === 'All' ? undefined : addCat,
            [town.length === 0 || town === 'All' ? undefined : 'town']: town.length === 0 || town === 'All' ? undefined : town
        }, currOffset - shoutResultsLimit)
    }

    const handleAddTypeChange = async (e) => {
        setAddType(e.target.value)
        setTipBorder(e.target.value === 'All' ? 'orange' : 'MediumAquaMarine')
        setTipLabel('Tip shoutbota')
        setIsResetFilterVisible(true)
        getShouts({
            [e.target.value === 'All' ? undefined : 'addType']: e.target.value === 'All' ? undefined : e.target.value,
            [addCat.length === 0 || addCat === 'All' ? undefined : 'addCat']: addCat.length === 0 || addCat === 'All' ? undefined : addCat,
            [town.length === 0 || town === 'All' ? undefined : 'town']: town.length === 0 || town === 'All' ? undefined : town
        }, currOffset)
    }

    const handleAddCatChange = async (e) => {
        setAddCat(e.target.value)
        setCatBorder(e.target.value === 'All' ? 'orange' : 'MediumAquaMarine')
        setCatLabel('Kategorija')
        setIsResetFilterVisible(true)
        getShouts({
            [e.target.value === 'All' ? undefined : 'addCat']: e.target.value === 'All' ? undefined : e.target.value,
            [addType.length === 0 || addType === 'All' ? undefined : 'addType']: addType.length === 0 || addType === 'All' ? undefined : addType,
            [town.length === 0 || town === 'All' ? undefined : 'town']: town.length === 0 || town === 'All' ? undefined : town
        }, currOffset)
    }

    const handleLeaveMM = async (id) => {
        try {
            const response = await axios.delete(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/shoutbox/${id}`
            )

            if (response.data.status === 1) {
                getShouts({ author: currUser }, 0)
                getCurrUserShoutCount(currUser)
                socket.emit('leaveMM')
            }
        } catch (e) {
            setShoutQueueInfo(`Nešto je pošlo po zlu (${e}).`)
        }
    }

    const generateMatchmakingQueueHeader = () => {
        return (
            <div>
                <div className="form-wrap margin-negative-btm margin-negative-top">

                    <div className="form-item">
                        <select
                            value={addType}
                            onChange={handleAddTypeChange}
                            className="mm-input"
                            id="addType"
                            name="addType"
                            style={{ borderColor: tipBorder }}
                        >
                            <option disabled selected key="tip" value="">Tip shoutbota:</option>
                            <option key="All2" value="All">Svi tipovi</option>
                            <option key="Prodajem2" value="Prodajem">Prodajem</option>
                            <option key="Iznajmljujem2" value="Iznajmljujem">Iznajmljujem</option>
                            <option key="Poklanjam2" value="Poklanjam">Poklanjam</option>
                            <option key="Nudim2" value="Nudim">Nudim</option>
                            <option key="Tražim2" value="Tražim">Tražim</option>
                        </select>
                        <label className="faded f12 margin-top-s fw200" htmlFor="addType">{tipLabel}</label>
                    </div>

                    <div className="form-item">
                        <select
                            value={addCat}
                            onChange={handleAddCatChange}
                            className="mm-input"
                            id="addCat"
                            name="addCat"
                            style={{ borderColor: catBorder }}
                        >
                            <option disabled selected key="kategorija" value="">Kategorija:</option>
                            <option key="All3" value="All">Sve kategorije</option>
                            <option key="Nekretnine2" value="Nekretnine">Nekretnine</option>
                            <option key="Auto/moto2" value="Auto/moto">Auto/moto</option>
                            <option key="Nautika2" value="Nautika">Nautika</option>
                            <option key="Usluge2" value="Usluge">Usluge</option>
                            <option key="Hrana/piće2" value="Hrana/piće">Hrana/piće</option>
                            <option key="Turizam2" value="Turizam">Turizam</option>
                            <option key="Sve za dom2" value="Sve za dom">Sve za dom</option>
                            <option key="Kućni ljubimci2" value="Kućni ljubimci">Kućni ljubimci</option>
                            <option key="Informatika2" value="Informatika">Informatika</option>
                            <option key="Mobiteli2" value="Mobiteli">Mobiteli</option>
                            <option key="Audio/video/foto2" value="Audio/video/foto">Audio/video/foto</option>
                            <option key="Glazbala2" value="Glazbala">Glazbala</option>
                            <option key="Literatura2" value="Literatura">Literatura</option>
                            <option key="Sportska oprema2" value="Sportska oprema">Sportska oprema</option>
                            <option key="Djeca2" value="Djeca">Djeca</option>
                            <option key="Strojevi i alati2" value="Strojevi i alati">Strojevi i alati</option>
                            <option key="Odjeća/obuća2" value="Odjeća/obuća">Odjeća/obuća</option>
                            <option key="Ostalo2" value="Ostalo">Ostalo</option>
                        </select>
                        <label className="faded f12 margin-top-s fw200" htmlFor="addCat">{catLabel}</label>
                    </div>

                    <div className="form-item">
                        <select
                            onChange={handleTownsChange}
                            value={town}
                            className="mm-input"
                            id="towns"
                            name="towns"
                            style={{ borderColor: locBorder }}
                        >
                            <option key="defV2" value="" disabled selected>Odaberi lokaciju</option>
                            <option key="All4" value="All">Sve lokacije</option>
                            {towns.map(town => <option key={`${Math.random()}${town}`} value={town}>{town}</option>)}
                        </select>
                        <label className="faded f12 margin-top-s fw200" htmlFor="towns">{locLabel}</label>
                    </div>
                </div>

                <div className="controls-titlebar border-top">
                    <div className="controls-titlebar_col1">
                        <div className="column margin-top-5 left">
                            {shoutQueueInfo.length !== 0 && <p className="form-p text-left">{shoutQueueInfo}</p>}

                            {shoutResultsCount > shoutResultsLimit &&
                                <div className="row middle margin-top-5">
                                    {currOffset !== 0 && <img onClick={handleChangeOffsetNegative} className="pointer margin-right-5" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA5klEQVR4nLXUTQ6CMBCGYdcocAt0785jKMaLSIx6EqPH0GOIXsOfuNKtrxlTkwqFFtAv6Qbok07LtNWyBOgAPTXatu+NAUJgCRzIR54tgMAVmwBX7LkAYxs2A5645wkkZSurgulobNozlzLLyg91UA7AlBuwcUQXOngswAbAzhFMP5hfgm2ptpdtAaPMizvQB4bAqmSsgUdmbvQX0P9pyZKCFqt6KPs3pkDpzaa/zVwHpewT9XPOXRbS6A1ab/SFaWhS43KYGjENjVUJLmWaV2ZAA3VQqQHaywHIvjthBtwDump4tgkvFTh48uLgnDMAAAAASUVORK5CYII=" />}
                                    <p className="form-p">{`Str. ${(currOffset / shoutResultsLimit) + 1}/${Math.ceil(shoutResultsCount / shoutResultsLimit)}`}</p>
                                    {currOffset + shoutResultsLimit < shoutResultsCount && <img onClick={handleChangeOffsetPositive} className="margin-left-5 pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6ElEQVR4nK2UTQ6CMBQGWaPgLdC9O4+hGC8iMeJJjB5DjwF6DX/iSreMKalJlZYWcJJuaJn06+ur51kA+sBIjp5tvRZgAGyAE1XEtxQIXWUL4I6dGzC3yVZAgTsFkNTtrIlMlca6M3OJWRd/oApFAVzYAw/DXKoKz47CIzAxSPOPLKAZB4O0KO8pEP1MPIEdsK0ZU2As16pEOuGrqzDgn5EFhhZrWpSslEmh6M2u12atCkXsC+25Vh4L0egdWm/2JVOkSYvHYamVKdJYRnCJqd+ZRhrKQuUaUSYKIM7dSaaR+8BQDt/2wxuv6XjxvQimTgAAAABJRU5ErkJggg==" />}
                                </div>}



                        </div>

                        <div className="row margin-top-5 middle">
                            {currUserShoutCount < 3 ? <p onClick={handleSetMM} className="content-button margin-top-s">Pokreni novog +</p> : <p className="form-p orange text-left">Obrišite jedan shout</p>}

                        </div>


                    </div>
                    <div className="controls-titlebar_col2">
                        <div className="row margin-top-5 middle">
                            {currUserShoutCount !== 0 && <p onClick={handleGetMyShouts} className="form-p pointer underline orange">{`Moji (${currUserShoutCount < 3 ? `${currUserShoutCount})` : `${currUserShoutCount})`}`}</p>}
                            {isResetFiltersVisible && <p onClick={resetFilters} className="form-p margin-left pointer underline mediumaquamarine">Resetiraj</p>}
                        </div>
                        <div className="column margin-negative-top-5">
                            <input type="text"
                                minLength={3}
                                maxLength={20}
                                name="search"
                                id='search'
                                className="mm-input"
                                value={searchTerm}
                               
                                style={{ borderColor: searchBorder }}
                                onChange={handleSearchChange}
                            />
                            <label className="faded f12 margin-top-s fw200" htmlFor="search">{searchLabel}</label>
                        </div>

                    </div>
                </div>
            </div>
        )
    }

    const generateContacts = (m) => {
        if (m.reacted.includes(currUser)) {
            return <p className="form-p mediumaquamarine text-left">Autor je notificiran. Eventualni odgovor ćete dobiti u inbox. </p>
        } else {
            return <p onClick={() => {
                notifyAuthor(
                    m.id,
                    m.author,
                    `Kontaktiram vas za vaš oglas: ${m.what} - ${m.addType} - ${m.addCat} - ${m.town}`)
            }} className="form-p underline pointer text-left">Notificiraj autora</p>
        }
    }

    const renderMMQueue = () => {
        const generateShoutBoxes = (m) => {

            const dateObject = new Date(m.createdAt);
            const day = dateObject.getDate();
            const month = dateObject.getMonth() + 1;
            const year = dateObject.getFullYear();
            const formattedDate = `${day}.${month}.${year}`;

            if (currUser !== 'TheCreator' && currUser !== 'Superadmin' && currUser !== m.author) {
                return (
                    <div className="margin-bottom w100 p-right-20">
                        <div className="shout-header">
                            <div className="shout-image-wrap">
                                <img className="shout-image" src={m.image} />
                            </div>
                            <div className='shout-content relative'>
                                <div className="margin-bottom">
                                    {generateContacts(m)}
                                </div>
                                <p className="text-left uppercase f12 fw200 orange"><span className="margin-right white">Tip oglasa:</span>{m.addType}</p>
                                <p className="text-left uppercase f12 fw200 orange"><span className="margin-right white">Kategorija:</span>{m.addCat}</p>
                                <p className="text-left uppercase f12 fw200 orange"><span className="margin-right white">Lokacija:</span>{m.town}</p>
                                <p className="text-left margin-top-s uppercase f12">{`Objavljeno: ${formattedDate}`}</p>
                                <p className="text-left blue margin-top-s">{m.what}</p>
                            </div>
                        </div>
                        <div className='shout-content shout-content-alt'>
                            <p className="text-left">{m.addDesc}</p>
                        </div>
                    </div>
                )
            } else {
                return (
                    <div className="margin-bottom w100 p-right-20">
                        <div className="shout-header">
                            <div className="shout-image-wrap">
                                <img className="shout-image" src={m.image} />
                            </div>
                            <div className="shout-content relative">
                                <span onClick={() => { handleLeaveMM(m.id) }} className="notification-number logout-ico pointer">x</span>
                                <span onClick={() => { handleEditMM(m.id) }} className="notification-number logout-ico pointer margin-top"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAhUlEQVR4nH3QsQqBYRQG4J+BRdyDjVLKqv5JbkBGi9XKFbgHm9XtmAx2i8mq1CM56u/v+7zTqfP01jlFUQta2GH1mev7Kpzi4ZsrOinUxh5DbHFDPwXX0fTEEYMUauIS8Jd5CvZwwivQGY0ULLHBCAcsctfOoumOyb+3jOMtyyyq4G5u+QZ5kJ0E6q+6pwAAAABJRU5ErkJggg==" /></span>
                                <p className="text-left uppercase f12 fw200 orange"><span className="margin-right white">Tip oglasa:</span>{m.addType}</p>
                                <p className="text-left uppercase f12 fw200 orange"><span className="margin-right white">Kategorija:</span>{m.addCat}</p>
                                <p className="text-left uppercase f12 fw200 orange"><span className="margin-right white">Lokacija:</span>{m.town}</p>
                                <p className="text-left margin-top-s uppercase f12">{`Objavljeno: ${formattedDate}`}</p>
                                <p className="text-left blue margin-top-s">{m.what}</p>
                            </div>
                        </div>
                        <div className='shout-content shout-content-alt'>
                            <p className="text-left">{m.addDesc}</p>
                        </div>
                    </div>
                )
            }
        }

        return (
            <div>
                {mm.map((m) => {
                    return (
                        <div className="message__chats margin-right" key={`${m.what}${Math.random()}`}>
                            {generateShoutBoxes(m)}
                        </div>
                    )
                })}
                <div className="min-height-100"></div>
            </div>
        )
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title border-none">
                    <div className="pop-up__title__content">
                        {generateMatchmakingQueueHeader()}
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    {renderMMQueue()}
                </div>

            </div>
        </div>
    )
}

export default ShoutsQueue