const StatusHistory = ({
    modalTitle,
    generateHeaderCloseIco,
    handleKickStatus,
    currUser,
    statusHistory
}) => {

    const renderStatusHistory = () => {
        return statusHistory.toReversed().map((status) => {
            if (currUser === 'TheCreator' || currUser === 'Superadmin') {
                return (
                    <div key={`${Math.random()}${status}`} className="relative w95">
                        <span onClick={() => { handleKickStatus(status) }} className="notification-number logout-ico pointer">x</span>
                        <p>{status}</p>
                    </div>
                )
            }

            return <p key={`${Math.random()}${status}`}>{`${status}`}</p>
        })
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title">
                    <div className="pop-up__title__content">
                        {modalTitle}
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    <div className="margin-top-m">
                    {renderStatusHistory()}
                    </div>
                </div>

            </div>
        </div>
    )

}

export default StatusHistory