import React, { useState, useRef } from 'react'
import { IoMdSend } from "react-icons/io";
import Picker from "emoji-picker-react";
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

const ChatFooter = ({ socket, newsId, pm, to, room, me, isWinter, isNews, handleMMBtnClick }) => {
  const [message, setMessage] = useState("")
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const inputElement = useRef(null);
  const [currLayout, setCurrLayout] = useState('default')
  const keyboard = useRef();
  const [keyboardVisible, setKeyboardVisible] = useState(false)

  const handleLayoutChange = (button) => {

    switch (button) {
      case "{shift}":
      case "{shiftactivated}":
      case "{default}":
        setCurrLayout(currLayout === "default" ? "shift" : "default")
        break;

      case "{alt}":
      case "{altright}":
        setCurrLayout(currLayout === "alt" ? "default" : "alt")
        break;

      case "{smileys}":
        setCurrLayout(currLayout === "smileys" ? "default" : "smileys")
        break;

      default:
        break;
    }
  }

  const handleKeyboardIcoClick = () => {
    setKeyboardVisible(!keyboardVisible)
  }

  const handleInputChange = (e) => {
    setMessage(e.target.value)
    if (keyboard.current) { //testiram jer baca grešku na serveru da ne mogu setInput na undefined
      keyboard.current.setInput(e.target.value);
    }
  }

  const handleEmojiPickerhideShow = () => {
    setShowEmojiPicker(!showEmojiPicker);
  };

  const handleEmojiClick = (event, emojiObject) => {
    let msg = message;
    msg += emojiObject.emoji;
    setMessage(msg);
  };

  const handleTyping = (e) => {

    if (e === "{downkeyboard}") {
      handleKeyboardIcoClick()
    }

    if (e.includes("{") && e.includes("}")) {
      handleLayoutChange(e);
    }

    socket.emit("typing", { key: e, name: localStorage.getItem("userName") })

    if (e === "{enter}") {
      if (message.trim() && localStorage.getItem("userName")) {
        const messageToSend = resolveMsgType()

        socket.emit(messageToSend,
          {
            text: message,
            name: localStorage.getItem("userName"),
            to,
            id: `${socket.id}${Math.random()}`,
            socketID: socket.id,
            room: room,
            [messageToSend === 'news-message' ? 'newsId' : null]: messageToSend === 'news-message' ? newsId : null
          }, localStorage.getItem('token'), me
        )
      }
      setMessage("")
      keyboard.current.clearInput();
    }
  }

  const resolveMsgType = () => {
    if (pm) {
      return 'pm-message'
    } else if (isNews) {
      return 'news-message'
    } else {
      return 'message'
    }
  }

  const handleSendMessage = (e) => {
    e.preventDefault()

    if (message.trim() && localStorage.getItem("userName")) {
      const messageToSend = resolveMsgType()

      socket.emit(messageToSend,
        {
          text: message,
          name: localStorage.getItem("userName"),
          to,
          id: `${socket.id}${Math.random()}`,
          socketID: socket.id,
          room: room,
          [messageToSend === 'news-message' ? 'newsId' : null]: messageToSend === 'news-message' ? newsId : null
        }, localStorage.getItem('token'), me
      )
    }
    setMessage("")
    inputElement.current.focus();

  }
  return (
    <div>
      {keyboardVisible && <div>
        {message.length !== 0 && <p className="keyboard-input">{message}</p>}
        <Keyboard
          keyboardRef={r => (keyboard.current = r)}
          onChange={e => setMessage(e)}
          onKeyPress={e => handleTyping(e)}
          theme="hg-theme-default hg-theme-ios"
          layout={
            {
              default: [
                "q w e r t y u i o p {bksp}",
                "a s d f g h j k l {enter}",
                "{shift} z x c v b n m , . {shift}",
                "{alt} {smileys} {space} {altright} {downkeyboard}"
              ],
              shift: [
                "Q W E R T Y U I O P {bksp}",
                "A S D F G H J K L {enter}",
                "{shiftactivated} Z X C V B N M , . {shiftactivated}",
                "{alt} {smileys} {space} {altright} {downkeyboard}"
              ],
              alt: [
                "1 2 3 4 5 6 7 8 9 0 {bksp}",
                `@ # $ & * ( ) ' " {enter}`,
                "{shift} % - + = / ; : ! ? {shift}",
                "{default} {smileys} {space} {back} {downkeyboard}"
              ],
              smileys: [
                "😀 😊 😅 😂 🙂 😉 😍 😛 😠 😎 {bksp}",
                `😏 😬 😭 😓 😱 😪 😬 😴 😯 {enter}`,
                "😐 😇 🤣 😘 😚 😆 😡 😥 😓 🙄 {shift}",
                "{default} {smileys} {space} {altright} {downkeyboard}"
              ]
            }
          }
          display={
            {
              "{alt}": ".?123",
              "{close}": "Zatvori",
              "{smileys}": "\uD83D\uDE03",
              "{shift}": "⇧",
              "{shiftactivated}": "⇧",
              "{enter}": "Pošalji",
              "{bksp}": "⌫",
              "{altright}": ".?123",
              "{downkeyboard}": "🞃",
              "{space}": " ",
              "{default}": "ABC",
              "{back}": "⇦"
            }
          }
          layoutName={currLayout}
        />
      </div>}
      {!keyboardVisible && <div className='chat__footer'>
        <div className="emoji-wrap">
          <div className="emoji row middle">
            <img className="pointer" onClick={handleEmojiPickerhideShow} src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAADiUlEQVR4nO1USUxTURStK42uoL+TttSCUEDDtEEcII6JxERl0OAQR4wLXWlMTNgYV26M0Y2uRMWYWGhLK5WqICh1QIkMpS2zmkBNXPS3Vmixv8fc9yTGBbUYl77k5+bee847777735XJ/q9/ufxO1TLRkrIvZBPuhh1qV7hVPfzVoXaRT3HK//XmgGyRaJafCrdqPkeep+H7awOkdxmQejKYJT/yXIevTo0/YBZqCb8wgVv6JUGb4v5MhxaxbgPi71cB/UZgYA3gzuO238jilCdcsFlxj3hJV0CESKeOnRp9WYA7H/CUAL5NgG8rt+RTvC+TVUb4oE3xIKmK6Iqm21cg1p3ONoC7APBuBIZ2AKNVwFgNMFLJfe8GJhTvy2R44gUsKScSClATQ49U/lmXnl/RwGrAsxYY2g6MVAHjR4GPZ4DxI1zIt43nB3IZPurSI+RQTU3aNEsTVCHUTLdRFQZ2ul9VlAOje7nIRC0XGa3+vZpeqsYA4ovmlL3zi1iFu5GOn73oz4K/swBX6/LRcKUEUU8FMLYfGDvEbNSzBw1X1rK8v5N6k8V6M9Ohg9gs3J5XJGRXvKLfkkT8bVk4sCsTl8/noe50Ac6dLARGKnhfRipwtraQxSl/cHcmwxOP+CG70pVARDkU6dBCatcCz7RAdzbgKQa8Zbwvw+XA8E5uyaf4YDHwxsjwUpsWxA/Zlb5ElbyMtKghWRWINwnAUx3Q+/P39ZYBvs2Abwu33lIep/xTHcNLFgWIH7QruxL15E7EroTUKEfcJIf0eAXiPbmAu4if2LMO8Kznlnx3EeI9ORxnkiPWKAfxRatQP7+IJWXftEOF2RYVJKcGn8xa1FYvx4Qzmz88dyEwWMStOx8TrUaWJxzhiUf8gDW1KuE7CdqV/plnWtBboUb2NuahtFiHw5UG3LyUg6brubhxMQeHK1eyeK+pgOFmXWmYadci+FA5mfCd0KJhF25Vs19x9uVKxN6lI9CeDdO1Dbhwej2O1xQya7q+EV8eGxF7m85whCdewJx6TJbc7BIawk4NG3zRLj2buFGXAZ+b1fjwQIDfqkJkLt6lZzjCU0//KPDbFG4W7oUfqdkLpuEXfZHGxgZdI1nyKU55wpEAHKsWJy0yVxENu6BdOUXX8O3Jcrbh3Ec+xakHSV1RokVNpFkkWoTbok3oCtkUw2RFi1AvmlOr/9jk/0u2wPUDbo9G/s0fMfYAAAAASUVORK5CYII=" />
            {showEmojiPicker && <Picker onEmojiClick={handleEmojiClick} />}
            <img
              onClick={handleKeyboardIcoClick}
              className="margin-right margin-left pointer"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAACO0lEQVR4nO3UW2iOcRwH8HcOIwohZyLUiiw5ZK1mVuSw1HIoKUU0oZyKou2G1S7EFVIsF4qUkAslOVworiQUyiEuJkJcOFx99Ndv9fR4t7V6Vy786un5v9/3d/z+vs+/UPiXDTWo6u0iJ7CtNwsMxEtUljrxeCzDfjxGWymTD8IlfMINHEU9ykpZ5DAup2KlSFaLfViLiRn8CRbEeTCW4wju4D1+KW6vU85sgT14FVRcieB3uIjvOIW7+IZbOIg6jEJ5J02vwdMs8BnTc05TsQ5N2IXFWcowFpswFI1YgZkhjGHoix9Jicl5ND5GYH10Wol5GJdJOgMjcBzXgl7xvh30LezAIuY5KtKhCg8CXBq0VMQurgY+O4ITBYeCrvStTEodd7Hn62mH6bAe5wMsy3U+NyPj1Yma+N0P/QPL0/SHuvBLU+8oBOctAX7BmeB7FYZkilbEpDUhgKI0dWARszeJKR3a0gIDTOpqDhknq84UuREUTkFrvIdgQBd0NSS1FqKT2tyffYKCrJpmYnhGeXOK0ZTDkoAepYC3scADeIj5obK6UF553FsNOIlpSf9By1805bAk76+pyM8Ye0lcH9MjSTFrDzVWp2k6oyk3fXshOmjKKWtoLLgxRt8coxe9FDEmRLEl/NPTgjfYmRwm434sdTsmdNNdWXSYfC8E3enGuBnKbI2nOX93pcBFOIcPeJY+xNhBCjiGs7gXCV/gNDakHXVHW2edzsJKbI3Rd2NjUDiyx0n/W6EH9hv1l+5xQdSJeAAAAABJRU5ErkJggg=="
            />
          </div>
        </div>
        <form className='form' onSubmit={handleSendMessage}>
          <input
            autoFocus
            ref={inputElement}
            type="text"
            placeholder='Napiši nešto...'
            className='message'
            value={message}
            onChange={handleInputChange}
            onKeyDown={e => handleTyping(e.key)}
            inputMode='none'
          />
          <div className="row center">
            <button className={`chatBtn content-button ${isWinter && 'christmass-btn'}`}>Pošalji</button>
            {!pm && <button onClick={handleMMBtnClick} className="footer-shout-btn"><img className="botimgttlbr-foot" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABUUlEQVR4nO3Uz0oDMRDH8bl40r6BHtqLf87qSxYrvoIHERE9tIX6Gtp3EAUPtUeFr8TmILjZlmxmN9H5wLJLYJL9ZbIrYkxZgD3gFnj31x2wLwWGeOM3N7YrpWDViZAbKQWroxSykFIArzVBXqQUwHVNkCspBTAAnitCuLGBlAToA5c/QrjnvpQKT0qHBVEEHAEj4AlYkt7Sz30GHGoE2AIugE/a8wGcu7VThnigO7MkYXwnujZK8U20eZzqjtlBkyDuw87FsEmQOfl4bBJE4xcba9EkSFZEOcgYOAG2/X2iVINmkHGg7j5xjXqQ40DdaeKab6leukovMFcvcU1QqiCtdkQzyCTwUtPENepB8H8ct5s7/j5VqlEP0imxIJkR60hmxDqSGfmzHakSO1ns+DoSK3aB2PF1LAgbso403LCNWUe80MZk25G2yL8PYoxE+wJmA95jdYQihQAAAABJRU5ErkJggg==" /></button>}
            
          </div>
          <IoMdSend />
        </form>
      </div>}

    </div>
  )
}

export default ChatFooter