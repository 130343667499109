import { HashRouter, Routes, Route, Navigate, Outlet } from "react-router-dom"
import HomePage from "./components/HomePage"
import ChatPage from "./components/ChatPage";
import socketIO from "socket.io-client"
import React, { Fragment } from 'react'

const {
  REACT_APP_NODE_ENV,
  REACT_APP_SERVER_DEV,
  REACT_APP_SERVER
} = process.env;

const socket = socketIO.connect(`${REACT_APP_NODE_ENV === 'development' ? REACT_APP_SERVER_DEV : REACT_APP_SERVER}`)

const App = () => {

  const PrivateRoute = () => {
    return localStorage.getItem("token") ? <Outlet /> : <Navigate to='/' />
  }

  const PublicRoute = () => {
    return localStorage.getItem("token") ? <Navigate to='/chat' /> : <Outlet />
  }

 

  return (
    <HashRouter>
        <Fragment>
          <Routes>
            <Route exact path='/' element={<PublicRoute />}>
              <Route exact path="/" element={<HomePage
                socket={socket}
              />}>
              </Route>
            </Route>
            <Route exact path='/:token' element={<PublicRoute />}>
              <Route exact path="/:token" element={<HomePage
                socket={socket}
              />}>
              </Route>
            </Route>
            <Route exact path='/chat' element={<PrivateRoute />}>
              <Route exact path='/chat' element={<ChatPage socket={socket} />} />
            </Route>
          </Routes>
        </Fragment>
    </HashRouter>

  );
}

export default App;
