import React from 'react'
import Keyboard from 'react-simple-keyboard';
import 'react-simple-keyboard/build/css/index.css';

const Practice = ({
    modalTitle,
    generateHeaderCloseIco,
    questBox
}) => {

    const [message, setMessage] = React.useState('')
    const [currQuest, setCurrQuest] = React.useState('')
    const [currIndex, setCurrIndex] = React.useState(0)
    const [currWPM, setCurrWPM] = React.useState(0)
    const [start, setStart] = React.useState(null)
    const [typed, setTyped] = React.useState('')
    const [accuracy, setAccuracy] = React.useState(0)
    const [currLayout, setCurrLayout] = React.useState('default')
    const keyboard = React.useRef();
    const [keyboardVisible, setKeyboardVisible] = React.useState(false)


    React.useEffect(() => {
        createQuest()
    }, [])

    const handleLayoutChange = (button) => {

        switch (button) {
            case "{shift}":
            case "{shiftactivated}":
            case "{default}":
                setCurrLayout(currLayout === "default" ? "shift" : "default")
                break;

            case "{alt}":
            case "{altright}":
                setCurrLayout(currLayout === "alt" ? "default" : "alt")
                break;

            default:
                break;
        }
    }

    const handleKeyboardIcoClick = () => {
        setKeyboardVisible(!keyboardVisible)
    }

    const generateTitleText = () => {
        return currQuest.split('').map((t, i) => {
            if (i === currIndex && t !== ' ') {
                return <span key={`${Math.random()}${t}`} className="next-sign">{t}</span>
            } else if (i === currIndex && t === ' ') {
                return <span key={`${Math.random()}${t}`} className="empty-space next-sign">&#9248;</span>
            } else if (i <= currIndex) {
                return <span key={`${Math.random()}${t}`} className="eliminated-sign">{t}</span>
            } else {
                return t
            }
        })
    }

    const createQuest = () => {
        let quest = ''
        for (let i = 0; i < 24; i++) {
            const randomNumber = Math.floor(Math.random() * questBox.length)
            quest = quest.concat(' ', questBox[randomNumber])
        }
        quest = quest.trim()
        setCurrQuest(quest)
    }

    function calculateWPM(text, startTime, endTime) {
        const words = text.trim().split(/\s+/);
        const numberOfWords = words.length;
        const durationInMinutes = (endTime - startTime) / 60000;
        const wpm = numberOfWords / durationInMinutes;
        setCurrWPM(wpm.toFixed(1))
    }

    function levenshteinDistance(a, b) {
        if (a.length === 0) return b.length;
        if (b.length === 0) return a.length;

        const matrix = Array.from(Array(a.length + 1), () =>
            Array(b.length + 1).fill(0)
        );

        for (let i = 0; i <= a.length; i++) {
            matrix[i][0] = i;
        }

        for (let j = 0; j <= b.length; j++) {
            matrix[0][j] = j;
        }

        for (let i = 1; i <= a.length; i++) {
            for (let j = 1; j <= b.length; j++) {
                const cost = a[i - 1] === b[j - 1] ? 0 : 1;
                matrix[i][j] = Math.min(
                    matrix[i - 1][j] + 1,
                    matrix[i][j - 1] + 1,
                    matrix[i - 1][j - 1] + cost
                );
            }
        }

        return matrix[a.length][b.length];
    }

    function calculateTypingAccuracy(referenceText, typedText) {
        const totalChars = referenceText.length;
        const levenshteinDist = levenshteinDistance(referenceText, typedText);
        const correctChars = totalChars - levenshteinDist;
        const ratio = (correctChars / totalChars) * 100;
        setTyped('')
        setAccuracy(ratio.toFixed(1))
    }

    const renderPractice = () => {
        return (
            <div>
                <div className="content-wrapper-header">
                    <div className="w100">
                        <div className="gamebox-title"><p className="noselect">{generateTitleText()}</p></div>
                        <div className="content-text noselect">{`${currWPM} riječi u minuti. Točnost: ${accuracy}%`}</div>
                    </div>
                </div>
            </div>
        )
    }

    const handleTyping = (e) => {
       
        let key = e.key || e //ako ne šalje preko tipkovnice onda je e.key a preko virtualne je samo e, ako nema e.key stavit će e

        if (e === '{space}') {
            key = ' '
            //ovo simuliramo space sa virtualne tipkovnice
        } else if (e === '{shift}') {
            key = 'Shift'
        } else if (e === '{alt}') {
            key = 'Alt'
        } else if (e === '{altright}') {
            key = 'Alt'
        } else if (e === '{downkeyboard}') {
            key = undefined
        } else if (e === '{bksp}') {
            key = 'Backspace'
        } else if (e === '{default}') {
            key = 'undefined'
        } else if (e === '{shiftactivated}') {
            key = 'undefined'
        }

        const rightKey = key === currQuest[currIndex]

        const wrongKey = () => {
            if (key === 'Alt') {
                return true
            } else if (key === 'Shift') {
                return true
            } else if ( key === 'Backspace') {
                return true
            } else if ( key === 'Space') {
                return true
            } else if ( key === 'Enter') {
                return true
            } else if ( key === 'undefined') {
                return true
            } else {
                return false
            }
        }

        console.log(key)

        if (!wrongKey()) {
            setTyped(`${typed}${key}`)
        }
        
        if (currIndex === 0) {
            setStart(new Date().getTime())
        }

        if (rightKey) {

            if (currIndex + 1 === currQuest.length) {
                setCurrIndex(0)
                createQuest()
                const end = new Date().getTime()
                calculateWPM(currQuest, start, end)
                console.log(`${typed}${key}`)
                calculateTypingAccuracy(currQuest, `${typed}${key}`)
                const clearInput = setTimeout(() => {
                    setMessage('')
                    clearTimeout(clearInput)
                    if (!e.key) {
                        keyboard.current.clearInput();
                    }  
                }, 500)
                return
            }

            setCurrIndex(currIndex + 1)
        }        

        if (e === "{downkeyboard}") {
            handleKeyboardIcoClick()
        }

        if (e.key) {
            return
        }

       if (e.includes("{") && e.includes("}")) {
            handleLayoutChange(e);
        }
    }

    const handleInputChange = (e) => {
        setMessage(e.target.value)
    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title">
                    <div className="pop-up__title__content">
                        {modalTitle}
                        {generateHeaderCloseIco()}
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    {renderPractice()}
                </div>
            </div>
            <div>
            {keyboardVisible && <div>
        <Keyboard
          keyboardRef={r => (keyboard.current = r)}
          onChange={e => setMessage(e)}
          onKeyPress={e => handleTyping(e)}
          theme="hg-theme-default hg-theme-ios"
          layout={
            {
              default: [
                "q w e r t y u i o p {bksp}",
                "a s d f g h j k l ;",
                "{shift} z x c v b n m , . {shift}",
                "{alt} {space} {altright} {downkeyboard}"
              ],
              shift: [
                "Q W E R T Y U I O P {bksp}",
                "A S D F G H J K L ;",
                "{shiftactivated} Z X C V B N M , . {shiftactivated}",
                "{alt} {space} {altright} {downkeyboard}"
              ],
              alt: [
                "1 2 3 4 5 6 7 8 9 0 {bksp}",
                `@ # $ & * ( ) ' "`,
                "{shift} % - + = / ; : ! ? {shift}",
                "{default} {space} {back} {downkeyboard}"
              ]
            }
          }
          display={
            {
              "{alt}": ".?123",
              "{close}": "Zatvori",
              "{smileys}": "\uD83D\uDE03",
              "{shift}": "⇧",
              "{shiftactivated}": "⇧",
              "{bksp}": "⌫",
              "{altright}": ".?123",
              "{downkeyboard}": "🞃",
              "{space}": " ",
              "{default}": "ABC",
              "{back}": "⇦"
            }
          }
          layoutName={currLayout}
        />
      </div>}
                {!keyboardVisible && <div className='chat__footer'>
                    <div className="emoji-wrap">
                        <div className="emoji row middle">
                            <img
                                onClick={handleKeyboardIcoClick}
                                className="margin-right margin-left pointer"
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAACO0lEQVR4nO3UW2iOcRwH8HcOIwohZyLUiiw5ZK1mVuSw1HIoKUU0oZyKou2G1S7EFVIsF4qUkAslOVworiQUyiEuJkJcOFx99Ndv9fR4t7V6Vy786un5v9/3d/z+vs+/UPiXDTWo6u0iJ7CtNwsMxEtUljrxeCzDfjxGWymTD8IlfMINHEU9ykpZ5DAup2KlSFaLfViLiRn8CRbEeTCW4wju4D1+KW6vU85sgT14FVRcieB3uIjvOIW7+IZbOIg6jEJ5J02vwdMs8BnTc05TsQ5N2IXFWcowFpswFI1YgZkhjGHoix9Jicl5ND5GYH10Wol5GJdJOgMjcBzXgl7xvh30LezAIuY5KtKhCg8CXBq0VMQurgY+O4ITBYeCrvStTEodd7Hn62mH6bAe5wMsy3U+NyPj1Yma+N0P/QPL0/SHuvBLU+8oBOctAX7BmeB7FYZkilbEpDUhgKI0dWARszeJKR3a0gIDTOpqDhknq84UuREUTkFrvIdgQBd0NSS1FqKT2tyffYKCrJpmYnhGeXOK0ZTDkoAepYC3scADeIj5obK6UF553FsNOIlpSf9By1805bAk76+pyM8Ye0lcH9MjSTFrDzVWp2k6oyk3fXshOmjKKWtoLLgxRt8coxe9FDEmRLEl/NPTgjfYmRwm434sdTsmdNNdWXSYfC8E3enGuBnKbI2nOX93pcBFOIcPeJY+xNhBCjiGs7gXCV/gNDakHXVHW2edzsJKbI3Rd2NjUDiyx0n/W6EH9hv1l+5xQdSJeAAAAABJRU5ErkJggg=="
                            />
                        </div>
                    </div>
                    <form className='form'>
                        <input
                            autoFocus
                            type="text"
                            placeholder='Vježbaj, pizda ti materina!'
                            className='message'
                            value={message}
                            onChange={handleInputChange}
                            onKeyDown={handleTyping}
                            inputmode='none'
                        />
                    </form>
                </div>}
            </div>
        </div>
    )
}

export default Practice