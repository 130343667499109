import React from 'react'
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import axios from 'axios'

const Slider = ({ isWinter, banners }) => {

    const sendEmail = () => {
        window.location = "mailto:ivankoperisic@gmail.com";
    }

    const myOptions = {
        type: 'loop',
        drag: 'free',
        perPage: 5,
        gap: '10px',
        arrows: false,
        pagination: false,
        autoWidth: true,
        autoScroll: {
            pauseOnHover: true,
            pauseOnFocus: false,
            rewind: false,
            speed: 1
        }
    }

    return (
        <div className="row margin-bottom">
            <div onClick={sendEmail} className="slider-banner relative">
                <h3>Vaš <br />banner <br />ovdje</h3>
                {isWinter === 1 && <img className="christmassdecor" src="christmass-decor.png"></img>}
                {isWinter === 1 && <img className="christmassdecor2" src="christmass-decor2.png"></img>}
            </div>
            <div className="slide-shape slider">
                <Splide
                    extensions={{ AutoScroll }}
                    options={myOptions}
                    aria-label="My Favorite Images">
                    {banners.length !== 0 && banners.map((b) => {
                        return <SplideSlide key={`${Math.random()}${b}`}>
                            <a href={b.link} target="_blank"><img width="90" height="90" className="slide-shape slider-img" src={b.logo} alt={b.title} /></a>
                        </SplideSlide>
                    })}
                </Splide>
            </div>
        </div>
    )
}

export default Slider