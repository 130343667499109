import React from 'react'

const Sidebar = ({
  socket,
  handleOpenDonationModal,
  getUsers,
  toggleUsers,
  showUsers,
  users,
  currUser,
  gamers,
  isWinter
}) => {

  return (
    <div className={showUsers ? 'show-users' : 'sidebar-wrapper'}>
      <div className="sidebar-header">
          {Object.keys(gamers).length !== 0 && <div>
            <div className="side-title">Multiplayer lobby</div>
            <div className="side-menu margin-bottom">
              {users.length !== 0 && getUsers('gamer')}
            </div>
          </div>}
          <div className="side-title">{`Online korisnici (${users.length})`}</div>
        </div>
        <div className="left-side">
          <div className="side-wrapper">
            {showUsers && <div onClick={toggleUsers} className="close-sidebar-right-mobile">
              x
            </div>}
            <div className="side-menu">
              {users.length !== 0 && getUsers('user')}
            </div>
          </div>
        </div>
      <div onClick={handleOpenDonationModal} className={`content-button row middle center sidebar-btn ${isWinter && 'christmass-btn'}`}>
        <img className="margin-right" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABkAAAAZCAYAAADE6YVjAAAACXBIWXMAAAsTAAALEwEAmpwYAAABjElEQVR4nO3UPUiXURQG8L8oOLQ4RGJTQx8IQYE4BCkEUdGYBBUlBQ4aLQVJIFFJH/8gakmxnApEcpQiMtqDlhYXQehjr6ChKX5x4b70crnva5F/WnzggXvhPOe55557bqOxgf8NbMU4DrUieT8eYRI7WmGwGfPoXPfkBXAAJxqtBA7iWCsSb8MHfMH3yLD+iF3rYbAbo2hW8Hx4CP9q8iSeuo5LdQnasB170VUT18RMfLYp71eJ2jGCz37jZzgR9mTi3+GtPFZyBh1YVI0fOJJoXkUGzCWVXMmZXLU2vqG7pHkapzvgMlZLfJgadOKrP8NkSXevZHIziZtOTU6HQcIErmMQF3AHh3EmNvkkLoX5iLpw+gcVJtdSk/DnvIlNPxf3F2Pyx7iBs7iF5eJ3xXApaWoylprcxUIFpzCAZ3E/hP1RF6osMJzo9qUmvTWT24xVzYZ7xlFsirowRwU+JY3vyw3f+zUa3oxXeqqk66mJ35l7wltwG8/xOsM5HM/M1otM7Mui2g00/ha/ANwhh+nPT9lzAAAAAElFTkSuQmCC" />
        <p>Počasti me</p>
      </div>
    </div>
  )
}

export default Sidebar