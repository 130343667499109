import React from 'react'
import UvjetiKoristenja from './UvjetiKoristenja'
import StatusHistory from './StatusHistory'
import Practice from './Practice'
import Donation from './Donation'
import News from './News'
import Admin from './Admin'
import PM from './PM'
import PMQueueList from './PMQueueList'
import MM from './MM'
import Shout from './Shout'
import ShoutsQueue from './ShoutsQueue'
import NotifyQueue from './Notifications'
import SeasonsModal from './SeasonsModal'
import EventsModal from './EventsModal'
import SingleNews from './SingleNews'
import AddNewsModal from './AddNewsModal'
import PlayListModal from './PlayListModal'
import Users from './Users'
import SingleUser from './SingleUser'
import axios from 'axios'

const {
    REACT_APP_DATABASE_BASEURL_DEV,
    REACT_APP_DATABASE_BASEURL,
    REACT_APP_NODE_ENV
  } = process.env;

const Modal = ({
    currUser,
    socket,
    handleCloseModal,
    title = 'Privatni razgovor',
    statusHistory,
    type,
    to,
    mmNew,
    setIsInMMNewQueue,
    handleKickMatchmaking,
    handleKickStatus,
    handleKickUser,
    questBox,
    users,
    banners,
    newsList,
    donators,
    setMessagesNum,
    messagesNum,
    setNotification,
    notification,
    me,
    currEvent,
    formatEventDate,
    singleNews,
    getNews,
    currSingleUserName,
    setCurrSingleUserName,
    songs,
    getSongs
}) => {

    const [modalType, setType] = React.useState(type)
    const [myShout, setMyShout] = React.useState(undefined)
    const [toWho, setToWho] = React.useState(to)
    const [toRoom, setToRoom] = React.useState(`${socket.id}${Math.random()}`)
    const [modalTitle, setModalTitle] = React.useState(title)
    const [currSingeNews, setCurrSingleNews] = React.useState(singleNews)

    currUser = localStorage.getItem('userName')

    const towns = ['Bakar', 'Beli Manastir', 'Belišće', 'Benkovac', 'Biograd na Moru', 'Bjelovar', 'Buje', 'Buzet', 'Cres', 'Crikvenica', 'Čabar', 'Čakovec', 'Čazma', 'Daruvar', 'Delnice', 'Donja Stubica', 'Donji Miholjac', 'Drniš', 'Dubrovnik', 'Duga Resa', 'Dugo Selo', 'Đakovo', 'Đurđevac', 'Garešnica', 'Glina', 'Gospić', 'Grubišno Polje', 'Hrvatska Kostajnica', 'Hvar', 'Ilok', 'Imotski', 'Ivanec', 'Ivanić-Grad', 'Jastrebarsko', 'Karlovac', 'Kastav', 'Kaštela', 'Klanjec', 'Knin', 'Komiža', 'Koprivnica', 'Korčula', 'Kraljevica', 'Krapina', 'Križevci', 'Krk', 'Kutina', 'Kutjevo', 'Labin', 'Lepoglava', 'Lipik', 'Ludbreg', 'Makarska', 'Mali Lošinj', 'Metković', 'Mursko Središće', 'Našice', 'Nin', 'Nova Gradiška', 'Novalja', 'Novigrad', 'Novi Marof', 'Novi Vinodolski', 'Novska', 'Obrovac', 'Ogulin', 'Omiš', 'Opatija', 'Opuzen', 'Orahovica', 'Oroslavje', 'Osijek', 'Otočac', 'Otok', 'Ozalj', 'Pag', 'Pakrac', 'Pazin', 'Petrinja', 'Pleternica', 'Ploče', 'Popovača', 'Poreč', 'Požega', 'Pregrada', 'Prelog', 'Pula', 'Rab', 'Rijeka', 'Rovinj', 'Samobor', 'Senj', 'Sinj', 'Sisak', 'Skradin', 'Slatina', 'Slavonski Brod', 'Slunj', 'Solin', 'Split', 'Stari Grad', 'Supetar', 'Sveta Nedelja', 'Sveti Ivan Zelina', 'Šibenik', 'Trilj', 'Trogir', 'Umag', 'Valpovo', 'Varaždin', 'Varaždinske Toplice', 'Velika Gorica', 'Vinkovci', 'Virovitica', 'Vis', 'Vodice', 'Vodnjan', 'Vrbovec', 'Vrbovsko', 'Vrgorac', 'Vrlika', 'Vukovar', 'Zabok', 'Zadar', 'Zagreb', 'Zaprešić', 'Zlatar', 'Županja']

    React.useEffect(() => {
        if (modalType === 'matchmakingQueue') {
            socket.emit('newMM')
        }
    }, [socket])

    const markAsRed = async (from) => {
        try {
            const response = await axios.put(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/mark-pm-as-red/${currUser}`,
                {
                    from
                }
            )
    
            if (response.data.status === 1) {
                setMessagesNum(messagesNum !== 0 ? messagesNum - 1 : 0)
            }
        } catch (e) {
           console.log(e)
        }
    }

    const joinRoom = (pm) => {
        setType('pvt')
        setToWho(pm.from)
        setToRoom(pm.room)
        setModalTitle(`Razgovarate sa ${pm.from}`)
        if (pm.type === 'pm') {
            markAsRed(pm.from)
            return
        }
    }

    const generateHeaderCloseIco = () => {
        return (
            <div className="form-close-ico-wrap">
                <svg onClick={handleCloseModal} className="close feather feather-x-circle" width="24" height="24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                    <circle cx="12" cy="12" r="10" />
                    <path d="M15 9l-6 6M9 9l6 6" />
                </svg>
            </div>
        )
    }

    return (
        <div>
            {modalType === 'seasons' && 
                <SeasonsModal 
                socket={socket}
                modalTitle={modalTitle}
                generateHeaderCloseIco={generateHeaderCloseIco}
                />
            }
            {modalType === "pvt" &&
                <PM
                    currUser={currUser}
                    socket={socket}
                    pm={true}
                    toWho={toWho}
                    from={currUser}
                    users={users}
                    modalTitle={modalTitle}
                    generateHeaderCloseIco={generateHeaderCloseIco}
                    toRoom={toRoom}
                    me={me}
                />}
            {modalType === 'statusHistory' &&
                <StatusHistory
                    generateHeaderCloseIco={generateHeaderCloseIco}
                    modalTitle={modalTitle}
                    handleKickStatus={handleKickStatus}
                    currUser={currUser}
                    statusHistory={statusHistory}
                />}
            {modalType === 'pmqueue' &&
                <PMQueueList
                    joinRoom={joinRoom}
                    socket={socket}
                    generateHeaderCloseIco={generateHeaderCloseIco}
                    modalTitle={modalTitle}
                    currUser={currUser}
                    users={users}
                />}
            {modalType === 'notifications' &&
                <NotifyQueue
                    modalTitle={modalTitle}
                    socket={socket}
                    currUser={currUser}
                    generateHeaderCloseIco={generateHeaderCloseIco}
                    joinRoom={joinRoom}
                    setNotification={setNotification}
                    setType={setType}
                    notification={notification}
                    newsList={newsList}
                />}
            {modalType === 'matchmaking' &&
                <Shout
                    currUser={currUser}
                    generateHeaderCloseIco={generateHeaderCloseIco}
                    modalTitle={modalTitle}
                    socket={socket}
                    towns={towns}
                    setType={setType}
                    myShout={myShout}
                />}
            {modalType === 'matchmakingQueue' &&
                <ShoutsQueue
                    modalTitle={modalTitle}
                    generateHeaderCloseIco={generateHeaderCloseIco}
                    currUser={currUser}
                    joinRoom={joinRoom}
                    towns={towns}
                    setType={setType}
                    setMyShout={setMyShout}
                    socket={socket}
                    toRoom={toRoom}
                    me={me}
                />}
            {modalType === 'uvjetiKoristenja' &&
                <UvjetiKoristenja
                    modalTitle='Uvjeti korištenja'
                    generateHeaderCloseIco={generateHeaderCloseIco}
                />}
            {modalType === 'MMNew' &&
                <MM
                    modalTitle='1-1 matchmaking'
                    generateHeaderCloseIco={generateHeaderCloseIco}
                    currUser={currUser}
                    socket={socket}
                    towns={towns}
                    setType={setType}
                    setIsInMMNewQueue={setIsInMMNewQueue}
                    me={me}
                />}
            {modalType === 'admin' &&
                <Admin
                    modalTitle={modalTitle}
                    generateHeaderCloseIco={generateHeaderCloseIco}
                    socket={socket}
                    users={users}
                    handleKickUser={handleKickUser}
                    banners={banners}
                    currUser={currUser}
                    questBox={questBox}
                />}
            {modalType === 'practice' &&
                <Practice
                    questBox={questBox}
                    modalTitle={modalTitle}
                    generateHeaderCloseIco={generateHeaderCloseIco}
                />}
            {modalType === 'news' &&
                <News
                    modalTitle='nethr.chat vijesti'
                    generateHeaderCloseIco={generateHeaderCloseIco}
                    newsList={newsList}
                    setType={setType}
                    setCurrSingleNews={setCurrSingleNews}
                    getNews={getNews}
                    currUser={currUser}
                />}
            {modalType === 'donation' &&
                <Donation
                    modalTitle={modalTitle}
                    generateHeaderCloseIco={generateHeaderCloseIco}
                    socket={socket}
                    donators={donators}
                />}
            {modalType === 'events' &&
                <EventsModal
                    modalTitle='nethr.chat events'
                    generateHeaderCloseIco={generateHeaderCloseIco}
                    socket={socket}
                    currEvent={currEvent}
                    formatEventDate={formatEventDate}
                />}
            {modalType === 'singlenews' &&
                <SingleNews
                    generateHeaderCloseIco={generateHeaderCloseIco}
                    setType={setType}
                    socket={socket}
                    currSingeNews={currSingeNews}
                    me={me}
                    currUser={currUser}
                />}
            {modalType === 'addnews' &&
                <AddNewsModal
                    generateHeaderCloseIco={generateHeaderCloseIco}
                    setType={setType}
                    socket={socket}
                    modalTitle='Dodaj vijest'
                    me={me}
                    getNews={getNews}
                />}
            {modalType === 'korisnici' &&
                <Users
                    generateHeaderCloseIco={generateHeaderCloseIco}
                    socket={socket}
                    modalTitle='Korisnici'
                    setType={setType}
                    towns={towns}
                    setCurrSingleUserName={setCurrSingleUserName}
                    me={me}
                />}
            {modalType === 'single-user' &&
                <SingleUser
                    generateHeaderCloseIco={generateHeaderCloseIco}
                    towns={towns}
                    currSingleUserName={currSingleUserName}
                    setType={setType}
                    currUser={currUser}
                    joinRoom={joinRoom}
                    me={me}
                    handleKickUser={handleKickUser}
                    socket={socket}
                />}
            {modalType === 'playlist' &&
                <PlayListModal
                    modalTitle='nethr.chat playlista'
                    generateHeaderCloseIco={generateHeaderCloseIco}
                    socket={socket}
                    songs={songs}
                    currUser={currUser}
                    getSongs={getSongs}
                />}
        </div>
    )
}

export default Modal