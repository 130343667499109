import React from 'react'
import axios from 'axios'

const {
    REACT_APP_DATABASE_BASEURL_DEV,
    REACT_APP_DATABASE_BASEURL,
    REACT_APP_NODE_ENV
} = process.env;

const Box = (props) => {
    const template = props.news.author.replace('.', '').replace(' ', '').toLowerCase()
    const id = props.news.id
    const author = props.news.author
    const autorDesc = props.news.authorDesc ? props.news.authorDesc : 'Unesite nešto o sebi/potpis'
    const logo = props.news.logo
    const title = props.news.title ? props.news.title : 'Unesite naslov'
    const sub = props.news.sub ? props.news.sub : 'Unesite podnaslov'
    const source = props.news.source
    const image = props.news.image ? props.news.image : 'https://www.alleycat.org/wp-content/uploads/2019/03/FELV-cat.jpg'
    const commentsNum = props.news.comments

    const generateCommentsLabel = () => {
        if (props.news.comments === 11) {
            return 'komentara'
        } else if (props.news.comments % 10 !== 1) {
            return 'komentara'
        } else {
            return 'komentar'
        }
    }

    const commentsLabel = generateCommentsLabel()
    let meta = props.news.meta
    meta = Array.from(meta)
    meta = [...meta]
    
    const renderMeta = () => {
        /* if (meta[0].text === '') {
            return `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.`
        } */

        return meta.map((m,) => {
            return m.link !== ''
                ?
                <a key={`${Math.random()}${m.text}`} className="box-meta-item" href={m.link} target="_blank">{m.text}</a>
                :
                <a key={`${Math.random()}${m.text}`} className="box-meta-item">{m.text}</a>
        })
    }

    const goToSource = () => {
        window.open(source)
    }

    const handleOpenNews = () => {
        if (props.isInModal) {
            props.setType('singlenews')
            props.setCurrSingleNews(id)
            return
        }

        props.handleOpenSingleNewsModal(id)
    }

    const handleDeleteNews = async (idToDelete) => {
        try {
            const response = await axios.delete(`${REACT_APP_NODE_ENV === 'development' ?
                REACT_APP_DATABASE_BASEURL_DEV :
                REACT_APP_DATABASE_BASEURL}/delete-news/${idToDelete}`)

            if (response.data.status === 1) {
                props.getNews()
            }
        } catch (e) {
            console.log(e)
        }
    }

    const showDeleteIco = () => {
        if (props.currUser === author) {
            return true
        }

        if (props.isInModal && props.currUser === 'TheCreator') {
            return true
        }

        if (props.isInModal && props.currUser === 'Superadmin') {
            return true
        }

        return false
    }

    return (
        <div className={`box box${props.isInSidebar && '-column'} relative`}>
            {showDeleteIco() && <span onClick={() => {handleDeleteNews(id)}}className="notification-number logout-ico pointer">x</span>}
            <div className={`box-content box-content${props.isInSidebar && '-column'} ${template}`}>
                <div className="box-title">{title}</div>
                <div onClick={goToSource} className="box-sub underline">{sub}</div>
                <div className="box-meta">
                    {renderMeta()}
                </div>
                <div className="row space-between">
                    <div className={`box-author box-author${props.isInSidebar && '-column'}`}>
                        <div className="box-uthor-img__wrapper">
                            <img className="box-author-img" src={logo} />
                        </div>
                        <div className="box-author-detail">
                            <div className="box-author-name">{author}</div>
                            <div className="box-author-info">{autorDesc}</div>
                        </div>
                    </div>
                    <div className="absolute-bottom-right column right">
                        {props.showBtn && <button onClick={handleOpenNews} className="content-button">{commentsNum === 0 ? 'Komentiraj' : `${commentsNum} ${commentsLabel}`}</button>}
                    </div>
                </div>
            </div>
            <div onClick={goToSource} className={`box-poster box-poster${props.isInSidebar && '-column'}`} style={{ backgroundImage: `url(${image})` }}></div>
        </div>
    )
}

export default Box