import React from 'react'
import axios from 'axios'

const {
    REACT_APP_DATABASE_BASEURL_DEV,
    REACT_APP_DATABASE_BASEURL,
    REACT_APP_NODE_ENV
} = process.env;

const limit = 4
const nums = [18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70]

const defaultSearchValues = {
    value: '',
    label: 'Unesi nadimak',
    color: ''
}

const defaultFilters = {
    town: '',
    gender: '',
    ageFrom: null,
    ageTo: null
}

const defaultFiltersLabels = {
    town: 'Odaberi lokaciju',
    gender: 'Odaberi spol',
    ageFrom: 'Godine od',
    ageTo: 'Godine do'
}

const defaultFiltersColors = {
    town: '',
    gender: '',
    ageFrom: '',
    ageTo: ''
}

/* const defaultCheckBoxesValues = {
    online: false,
    lastLogin: false,
    newest: false
} */

/* const defaultCheckBoxesLabelsColors = {
    online: '',
    lastLogin: '',
    newest: ''
} */

const Users = ({
    generateHeaderCloseIco,
    setType,
    towns,
    setCurrSingleUserName
}) => {

    const [users, setUsers] = React.useState(null)
    const [currOffset, setCurrOffset] = React.useState(0)
    const [usersResultsCount, setUsersResultsCount] = React.useState(0)
    const [filterInfoMessage, setFilterInfoMessage] = React.useState('')
    //const [checkBox, setCheckBox] = React.useState(defaultCheckBoxesValues)
    const [filters, setFilters] = React.useState(defaultFilters)
    const [filterLabel, setFilterLabel] = React.useState(defaultFiltersLabels)
    const [filterColor, setFilterColor] = React.useState(defaultFiltersColors)
    //const [checkboxLabelColor, setCheckboxLabelColor] = React.useState(defaultCheckBoxesLabelsColors)
    const [searchConfig, setSearchConfig] = React.useState(defaultSearchValues)

    React.useEffect(() => {
        getUsers({}, currOffset)
    }, [])

    const handleUserSelect = (selectedName) => {
        setType('single-user')
        setCurrSingleUserName(selectedName)
    }

    const resetAll = () => {
        //setCheckBox(defaultCheckBoxesValues)
        setFilters(defaultFilters)
        setFilterLabel(defaultFiltersLabels)
        setFilterColor(defaultFiltersColors)
        //setCheckboxLabelColor(defaultCheckBoxesLabelsColors)
        setCurrOffset(0)
        getUsers({}, 0)
    }

    const handleResetAll = () => {
        resetAll()
        setSearchConfig(defaultSearchValues)
    }

    const getUsers = async (filterConfig, offset, ageFrom, ageTo) => {

        /* const sortUsers = (toSort) => {
            const sortIndex = Object.values(checkBox).findIndex(val => val === true)
            
            if (sortIndex === -1) {
                return toSort
            }

            const sortKey = Object.keys(checkBox)[sortIndex]
           
            if (sortKey === 'online') {
                return sortedUsers(toSort)
            } else if (sortKey === 'lastLogin') {
                return lastLoginSorter(toSort)
            } else if (sortKey === 'newest') {
                return newUsersSorter(toSort)
            } 

        } */

        const generateFormatedDateFromNumber = (num) => {
            const resultDate = new Date();
            resultDate.setFullYear(resultDate.getFullYear() - Number(num));
            const formattedDate = resultDate.toISOString().replace("T", " ").substring(0, 19);
            return formattedDate
        }

        const startDate = `${!ageFrom || ageFrom === 'All' ? undefined : generateFormatedDateFromNumber(Number(ageFrom))}`
        const endDate = `${!ageTo || ageTo === 'All' ? undefined : generateFormatedDateFromNumber(Number(ageTo))}`

        try {
            const response = await axios.post(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/users-filter/${offset}/${limit}`,
                {
                    filterConfig,
                    attributes: ['id', 'updatedAt', 'createdAt', 'name', 'location', 'birthDate', 'gender', 'level', 'avatar', 'status', 'statusMessage'],
                    [!ageFrom || ageFrom === 'All' ? undefined : 'startDate']: !ageFrom || ageFrom === 'All' ? undefined : startDate,
                    [!ageTo || ageTo === 'All' ? undefined : 'endDate']: !ageTo || ageTo === 'All' ? undefined : endDate
                }
            )

            if (response.data.status === 1) {
                //setUsers(sortUsers(response.data.data.rows))
                setUsers(sortedUsers(response.data.data.rows))
                setUsersResultsCount(response.data.data.count)
                setFilterInfoMessage(`Pronađeno: ${response.data.data.count}`)
            }
        } catch (e) {

        }
    }

    const searchUsers = async (term, offset) => {
        try {
            const response = await axios.post(
                `${REACT_APP_NODE_ENV === 'development' ?
                    REACT_APP_DATABASE_BASEURL_DEV :
                    REACT_APP_DATABASE_BASEURL}/users-search/${offset}/${limit}`,
                {
                    term
                }
            )

            if (response.data.status === 1) {
                setUsers(response.data.data.rows)
                setUsersResultsCount(response.data.data.count)
                setFilterInfoMessage(`Pronađeno: ${response.data.data.count}`)
            }
        } catch (e) {
            setFilterInfoMessage(`Nešto je pošlo po zlu (${e}).`)
        }
    }

   /*  const handleChangeCheckboxLabelColor = (checkboxName) => {
        setCheckboxLabelColor({ ...defaultCheckBoxesLabelsColors, [checkboxName]: 'mediumaquamarine' })
    } */

    const handleAgeFromChange = (e) => {
        setSearchConfig(defaultSearchValues)
        setFilters({ ...filters, ageFrom: e.target.value })
        setFilterLabel({ ...filterLabel, ageFrom: 'Od' })
        setFilterColor({ ...filterColor, ageFrom: `${e.target.value === 'All' ? 'orange' : 'mediumaquamarine'}` })
        getUsers({
            [filters.gender.length === 0 || filters.gender === 'All' ? undefined : 'gender']: filters.gender.length === 0 || filters.gender === 'All' ? undefined : `${getGender(filters.gender)}`,
            [filters.town.length === 0 || filters.town === 'All' ? undefined : 'location']: filters.town.length === 0 || filters.town === 'All' ? undefined : filters.town
        }, currOffset, e.target.value, filters.ageTo)
    }

    const handleAgeToChange = (e) => {
        setSearchConfig(defaultSearchValues)
        setFilterLabel({ ...filterLabel, ageTo: 'Do' })
        setFilterColor({ ...filterColor, ageTo: `${e.target.value === 'All' ? 'orange' : 'mediumaquamarine'}` })
        getUsers({
            [filters.gender.length === 0 || filters.gender === 'All' ? undefined : 'gender']: filters.gender.length === 0 || filters.gender === 'All' ? undefined : `${getGender(filters.gender)}`,
            [filters.town.length === 0 || filters.town === 'All' ? undefined : 'location']: filters.town.length === 0 || filters.town === 'All' ? undefined : filters.town
        }, currOffset, filters.ageFrom, e.target.value)
    }

    const handleGenderChange = (e) => {
        setSearchConfig(defaultSearchValues)
        setFilters({ ...filters, gender: e.target.value })
        setFilterLabel({ ...filterLabel, gender: 'Spol' })
        setFilterColor({ ...filterColor, gender: `${e.target.value === 'All' ? 'orange' : 'mediumaquamarine'}` })
        getUsers({
            [e.target.value === 'All' ? undefined : 'gender']: e.target.value === 'All' ? undefined : `${getGender(e.target.value)}`,
            [filters.town.length === 0 || filters.town === 'All' ? undefined : 'location']: filters.town.length === 0 || filters.town === 'All' ? undefined : filters.town
        }, currOffset, filters.ageFrom, filters.ageTo)
    }

    const getGender = (genderLetter) => {
        if (genderLetter === 'M') {
            return 'muško'
        } else if (genderLetter === 'Ž') {
            return 'žensko'
        } else if (genderLetter === 'N') {
            return 'nebinaran'
        }
    }

    const handleTownsChange = async (e) => {
        setSearchConfig(defaultSearchValues)
        setFilters({ ...filters, town: e.target.value })
        setFilterLabel({ ...filterLabel, town: 'Lokacija' })
        setFilterColor({ ...filterColor, town: `${e.target.value === 'All' ? 'orange' : 'mediumaquamarine'}` })
        getUsers({
            [e.target.value === 'All' ? undefined : 'location']: e.target.value === 'All' ? undefined : e.target.value,
            [filters.gender.length === 0 || filters.gender === 'All' ? undefined : 'gender']: filters.gender.length === 0 || filters.gender === 'All' ? undefined : `${getGender(filters.gender)}`
        }, currOffset, filters.ageFrom, filters.ageTo)
    }

     const sortedUsers = (toSort) => {
        return toSort.sort((a, b) => {
            return (a.status === 1 ? -1 : b.status === 1 ? 1 : 0)
        })
    }

   /*  const handleSetOnlineSorter = () => {
        setSearchConfig(defaultSearchValues)
        setCheckBox({ ...defaultCheckBoxesValues, online: true })
        setUsers(sortedUsers(users))
        handleChangeCheckboxLabelColor('online')
    } */

    /* const lastLoginSorter = (toSort) => {
        return toSort.sort((a, b) => {
            const dateA = new Date(a.updatedAt);
            const dateB = new Date(b.updatedAt);
            const today = new Date();

            // Calculate the time difference in milliseconds
            const diffA = Math.abs(today - dateA);
            const diffB = Math.abs(today - dateB);

            // Users with updatedAt closer to today's date come first
            return diffA - diffB;
        });
    } */

    /* const handleSetLastLoginSorter = () => {
        setSearchConfig(defaultSearchValues)
        setCheckBox({ ...defaultCheckBoxesValues, lastLogin: true })
        setUsers(lastLoginSorter(users))
        handleChangeCheckboxLabelColor('lastLogin')
    } */

    /* const newUsersSorter = (toSort) => {
        return toSort.sort((a, b) => {
            console.log(a.createdAt, b.createdAt)
            const dateA = new Date(a.createdAt);
            const dateB = new Date(b.createdAt);
            const today = new Date();

            // Calculate the time difference in milliseconds
            const diffA = Math.abs(today - dateA);
            const diffB = Math.abs(today - dateB);

            // Users with updatedAt closer to today's date come first
            return diffB - diffA        });
    }
 */
    /* const handleSetNewesSorter = () => {
        setSearchConfig(defaultSearchValues)
        setCheckBox({ ...defaultCheckBoxesValues, newest: true })
        setUsers(newUsersSorter(users))
        handleChangeCheckboxLabelColor('newest')
    } */

    const handleChangeOffsetPositive = () => {
        setCurrOffset(currOffset + limit)
        getUsers({
            [filters.gender.length === 0 || filters.gender === 'All' ? undefined : 'gender']: filters.gender.length === 0 || filters.gender === 'All' ? undefined : `${getGender(filters.gender)}`,
            [filters.town.length === 0 || filters.town === 'All' ? undefined : 'location']: filters.town.length === 0 || filters.town === 'All' ? undefined : filters.town
        }, currOffset + limit, filters.ageFrom, filters.ageTo)
    }

    const handleChangeOffsetNegative = () => {
        setCurrOffset(currOffset - limit)
        getUsers({
            [filters.gender.length === 0 || filters.gender === 'All' ? undefined : 'gender']: filters.gender.length === 0 || filters.gender === 'All' ? undefined : `${getGender(filters.gender)}`,
            [filters.town.length === 0 || filters.town === 'All' ? undefined : 'location']: filters.town.length === 0 || filters.town === 'All' ? undefined : filters.town
        }, currOffset - limit, filters.ageFrom, filters.ageTo)
    }

    const handleSearchChange = (e) => {
        resetAll()

        if (e.target.value.length === 0) {
            setSearchConfig({ 
                color: '', 
                label: 'Unesi nadimak',
                value: ''
            })
            getUsers({}, 0)
        } else if (e.target.value.length <= 10 && e.target.value.length >= 6) {
            setSearchConfig({ ...searchConfig, 
                color: 'mediumaquamarine', 
                label: 'Bravo. Rezultati će se pojavit ispod',
                value: e.target.value
            })
        } else {
            setSearchConfig({ ...searchConfig, 
                color: 'orange', 
                label: 'Unesite minimalno 6, maksimalno 10 znakova',
                value: e.target.value
            })
        }

        if (e.target.value.length >= 6) {
            searchUsers(e.target.value, 0)
        }

        
    }

    const generateUsersHeader = () => {
        return (
            <div>
                <div className="form-wrap margin-negative-btm margin-negative-top">
                    
                    <div className="form-item">
                        <select style={{ borderColor: filterColor['town'] }} onChange={handleTownsChange} value={filters.town} className="mm-input" id="towns" name="towns">
                            <option disabled selected key="N" value="">Lokacija:</option>
                            <option key="A" value="All">Sve lokacije:</option>
                            {towns.map(town => <option key={town} value={town}>{town}</option>)}
                        </select>
                        <label className="fw200 faded f12 margin-top-s" htmlFor="towns">{filterLabel.town}</label>
                    </div>

                    <div className="form-item">
                        <select style={{ borderColor: filterColor['gender'] }} value={filters.gender} onChange={handleGenderChange} className="mm-input" id="gender" name="gender">
                            <option disabled selected key="F" value="">Spol:</option>
                            <option key="A1" value="All">Svi:</option>
                            <option key="M" value="M">muško</option>
                            <option key="Ž" value="Ž">žensko</option>
                            <option key="N" value="N">nebinaran</option>
                        </select>
                        <label className="fw200 faded f12 margin-top-s" htmlFor="gender">{filterLabel.gender}</label>
                    </div>

                    <div className="form-item">
                        <select style={{ borderColor: filterColor['ageFrom'] }} value={filters.ageFrom} onChange={handleAgeFromChange} className="mm-input" id="age-from" name="age-from">
                            <option disabled selected key="N" value="">Od:</option>
                            <option key="A2" value="All">Sve godine:</option>
                            {nums.map(num => <option key={num} value={num}>{`${num} god.`}</option>)}
                        </select>
                        <label className="fw200 faded f12 margin-top-s" htmlFor="age-from">{filterLabel.ageFrom}</label>
                    </div>

                    <div className="form-item">
                        <select style={{ borderColor: filterColor['ageTo'] }} value={filters.ageTo} onChange={handleAgeToChange} className="mm-input" id="age-to" name="age-to">
                            <option disabled selected key="N" value="">Do:</option>
                            <option key="A3" value="All">Sve godine:</option>
                            {nums.map(num => <option key={num} value={num}>{`${num} god.`}</option>)}
                        </select>
                        <label className="fw200 faded f12 margin-top-s" htmlFor="age-to">{filterLabel.ageTo}</label>
                    </div>

                </div>

                <div className="controls-titlebar border-top">
                    <div className="controls-titlebar_col1">

                        {/* <div className="row margin-top-m">
                            <div className="checkbox-wrap margin-bottom-0">
                                <input
                                    type="radio"
                                    id="checkBox"
                                    name="checkbox"
                                    checked={checkBox.online}
                                    onChange={handleSetOnlineSorter}
                                />
                                <label style={{ color: `${checkboxLabelColor['online']}` }} htmlFor="checkBox" className="fw200 margin-left form-p noselect">Online</label>
                            </div>
                            <div className="checkbox-wrap margin-left margin-bottom-0">
                                <input
                                    type="radio"
                                    id="lastCheckin"
                                    name="checkbox"
                                    checked={checkBox.lastLogin}
                                    onChange={handleSetLastLoginSorter}
                                />
                                <label style={{ color: `${checkboxLabelColor['lastLogin']}` }} htmlFor="lastCheckin" className="fw200 margin-left form-p noselect">Zadnja prijava</label>
                            </div>
                            <div className="checkbox-wrap margin-left margin-bottom-0">
                                <input
                                    type="radio"
                                    id="novi"
                                    name="checkbox"
                                    checked={checkBox.newest}
                                    onChange={handleSetNewesSorter}
                                />
                                <label style={{ color: `${checkboxLabelColor['newest']}` }} htmlFor="novi" className="fw200 margin-left form-p noselect">Novi</label>
                            </div>
                        </div> */}

                        <div className="row margin-top-5">
                            <p onClick={handleResetAll} className="form-p pointer mediumaquamarine">Poništi sve filtere</p>
                        </div>

                        <div className="margin-top-5 pagina">
                            {filterInfoMessage.length !== 0 && <p className="form-p margin-right">{filterInfoMessage}</p>}

                            {usersResultsCount > limit &&
                                <div className="row middle">
                                    {currOffset !== 0 && <img onClick={handleChangeOffsetNegative} className="pointer margin-right-5" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA5klEQVR4nLXUTQ6CMBCGYdcocAt0785jKMaLSIx6EqPH0GOIXsOfuNKtrxlTkwqFFtAv6Qbok07LtNWyBOgAPTXatu+NAUJgCRzIR54tgMAVmwBX7LkAYxs2A5645wkkZSurgulobNozlzLLyg91UA7AlBuwcUQXOngswAbAzhFMP5hfgm2ptpdtAaPMizvQB4bAqmSsgUdmbvQX0P9pyZKCFqt6KPs3pkDpzaa/zVwHpewT9XPOXRbS6A1ab/SFaWhS43KYGjENjVUJLmWaV2ZAA3VQqQHaywHIvjthBtwDump4tgkvFTh48uLgnDMAAAAASUVORK5CYII=" />}
                                    <p className="form-p">{`Stranica ${(currOffset / limit) + 1}/${Math.ceil(usersResultsCount / limit)}`}</p>
                                    {currOffset + limit < usersResultsCount && <img onClick={handleChangeOffsetPositive} className="margin-left-5 pointer" src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAYAAACNiR0NAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6ElEQVR4nK2UTQ6CMBQGWaPgLdC9O4+hGC8iMeJJjB5DjwF6DX/iSreMKalJlZYWcJJuaJn06+ur51kA+sBIjp5tvRZgAGyAE1XEtxQIXWUL4I6dGzC3yVZAgTsFkNTtrIlMlca6M3OJWRd/oApFAVzYAw/DXKoKz47CIzAxSPOPLKAZB4O0KO8pEP1MPIEdsK0ZU2As16pEOuGrqzDgn5EFhhZrWpSslEmh6M2u12atCkXsC+25Vh4L0egdWm/2JVOkSYvHYamVKdJYRnCJqd+ZRhrKQuUaUSYKIM7dSaaR+8BQDt/2wxuv6XjxvQimTgAAAABJRU5ErkJggg==" />}
                                </div>}

                        </div>
                    </div>
                    <div className="controls-titlebar_col2">
                        <input type="text"
                            minLength={6}
                            maxLength={10}
                            name="search"
                            id='search'
                            className="mm-input"
                            value={searchConfig.value}
                            placeholder="Pretraži članove"
                            style={{ borderColor: searchConfig.color }}
                            onChange={handleSearchChange}
                        />
                        <label className="faded f12 margin-top-s fw200" htmlFor="search">{searchConfig.label}</label>
                    </div>
                </div>
            </div>
        )
    }

    const renderUsers = () => {
        return users.map(u => {

            const givenDate = new Date(u.birthDate);
            const currentDate = new Date();
            const timeDiff = currentDate - givenDate;
            const years = timeDiff / (1000 * 60 * 60 * 24 * 365.25);

            return (
                <div key={u.avatar} onClick={() => { handleUserSelect(u.name) }} className='user-wrap user-wrap-alt padding20 margin-bottom-10 message__recipient pointer relative row'>
                    <div className="profile-image-wrap">
                        <img className="profile-image margin-top-m" src={u.avatar} />
                    </div>
                    <div className="profile-content w70 p-right-10 relative">
                        {u.status === 1 && <p className="absolute-top-right form-p mediumaquamarine"><div className="online"></div></p>}
                        <p className="blue margin-top-s">{u.name}</p>
                        <p className="uppercase f10 margin-bottom-5">{`Level: ${u.level}`}</p>
                        {u.statusMessage && <p className="f12 fw200 margin-bottom-5"><strong>Status: </strong>{u.statusMessage}</p>}
                        <p className="f12">{`${Math.round(years)} god.`}</p>
                        <p className="f12 margin-bottom">{`${u.gender}, ${u.location}`}</p>
                    </div>
                </div>
            )
        })
    }

    if (users) {
        return (
            <div className="pop-up">
                <div className="pop-up-content">
                    <div className="pop-up__title border-none">
                        <div className="pop-up__title__content">
                            {generateUsersHeader()}
                            {generateHeaderCloseIco()}
                        </div>
                    </div>
                    <div className="modal-content-wrapper">
                        <div className="multiline space-between p-right-20">
                            {renderUsers()}
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default Users