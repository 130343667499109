import React from 'react'

const SeasonBox = ({ season, showBtn, handleOpenSeasonsModal, isInSidebar }) => {
    const generateAvatars = () => {
        return season.top5Names.map((user, index) => {
            return <div key={`${user}${Math.random()}`}
                style={{
                    left: `${index * 25}px`
                }}
                className="home-gamer">
                <img className="avatar-img hgamers-img" src={user.avatar}></img>
            </div>
        })
    }

    const generateSeasonText = () => {
        const top5 = season.top5Names
        return top5.map((item, index) => {
            return `${item.name} (${item.energy}), `
        })
    }

    if (season) {
        return (
            <div className={`content-wrapper-header seasons seasons${isInSidebar && '-column'}`} >

                <div className={`seasons-left seasons-left${isInSidebar && '-column'}`}>
                    <div className="gamebox-title"><p className="noselect">{season.seasonName}</p></div>
                    <div className="home-gamers-wrap">
                        {generateAvatars()}
                    </div>
                    <div className="content-text">Najviše energije:
                        <p className="gmbxtxt fw400 fs14 lh17">{generateSeasonText()}</p>
                    </div>
                </div>

                <div className={`seasons-right seasons-right${isInSidebar && '-column'}`}>
                    <div className="content-text margin-bottom">{`Trajanje sezone: ${season.duration}`}</div>

                    <div>
                        <div className="row middle margin-bottom-5">
                            {Object.keys(season.topGuns.shield).length !== 0 ? <img className="avatar-img hgamers-img" src={season.topGuns.shield.avatar}></img> : null}
                            <p className="gmbxtxt fw400 fs14 lh17 margin-left">
                            Branitelj: 
                            <span className="block margin-negative-top-5">
                            {Object.keys(season.topGuns.shield).length !== 0 ? season.topGuns.shield.name : 'Nije bilo branitelja'}
                            {Object.keys(season.topGuns.shield).length !== 0 ? ` (${season.topGuns.shield.count})`: null}
                            </span>
                            </p>
                        </div>
                        <div className="row middle margin-bottom-50">
                            {Object.keys(season.topGuns.sword).length !== 0 ? <img className="avatar-img hgamers-img" src={season.topGuns.sword.avatar}></img> : null}
                            <p className="gmbxtxt fw400 fs14 lh17 margin-left">
                            Napadač: 
                            <span className="block margin-negative-top-5">
                            {Object.keys(season.topGuns.sword).length !== 0 ? season.topGuns.sword.name : 'Nije bilo napadača'}
                            {Object.keys(season.topGuns.sword).length !== 0 ? ` (${season.topGuns.sword.count})`: null}
                            </span>
                            </p>
                        </div>
                    </div>
                    {showBtn && <a onClick={handleOpenSeasonsModal} className="content-button absolute-bottom-right">Sve sezone</a>}
                </div>

            </div>
        )
    }
}

export default SeasonBox