import Box from './Box'

const News = ({
    modalTitle,
    generateHeaderCloseIco,
    newsList,
    setType,
    setCurrSingleNews,
    getNews,
    currUser
}) => {

    const handleAddNewsClick = () => {
        setType('addnews')
    }

    const renderNews = () => {

        return newsList.map((news) => {
            return <div key={`${Math.random()}`} className="margin-right">
                <Box currUser={currUser} getNews={getNews} setCurrSingleNews={setCurrSingleNews} setType={setType} isInModal={true} key={`${Math.random()}${newsList.length}`} news={news} showBtn={true} />
            </div>
        })

    }

    return (
        <div className="pop-up">
            <div className="pop-up-content">
                <div className="pop-up__title border-none">
                    <div className="pop-up__title__content">
                        {modalTitle}
                        {generateHeaderCloseIco()}
                        <div className="controls-titlebar border-top">
                        <p onClick={handleAddNewsClick} className="content-button margin-top margin-bottom">Dodaj novu vijest ili uredi svoju +</p>
                        </div>
                    </div>
                </div>
                <div className="modal-content-wrapper">
                    {renderNews()}
                </div>

            </div>
        </div>
    )
}

export default News